import { Center, Icon, Image, Spinner } from "@chakra-ui/react";
import { FC } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useResumeThumbnail } from "../hooks/use-resume-thumbnail";

const PreviewFallback: FC<{ loading?: boolean }> = ({ loading }) => {
  return (
    <Center flexDirection="column" h="lg">
      <Icon as={AiOutlineFilePdf} fontSize="4rem" mb="4" />
      {loading && <Spinner label="Loading resume thumbnail" />}
    </Center>
  );
};

export const ResumePreview: FC = () => {
  const { isNew, url } = useResumeThumbnail();

  if (isNew) {
    return <PreviewFallback loading />;
  }

  return <Image src={url} fallback={<PreviewFallback />} mx="auto" />;
};
