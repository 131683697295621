import { Box, Center, Heading, Spinner, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useHomepageUrl } from "../feature/Company";

export const Success = () => {
  const navigate = useNavigate();
  const hompageUrl = useHomepageUrl();

  useEffect(() => {
    async function redirectPage() {
      await new Promise((r) => setTimeout(r, 3500));
      return navigate(hompageUrl);
    }
    redirectPage();
  }, []);

  return (
    <Center minH="80vh">
      <Box textAlign="center" fontWeight="bold">
        <Heading fontSize={{ base: "28px", lg: "48px" }}>Success!</Heading>
        <Text fontSize={{ base: "28px", lg: "48px" }}>
          Your application is on its way
        </Text>
        <Box mt="10">
          <Spinner />
        </Box>
      </Box>
    </Center>
  );
};
