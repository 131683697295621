import { Container } from "@chakra-ui/react";
import { SimpleNavBar } from "@cx/ui-common";
import { FormRowProvider } from "@cx/ui-form";
import { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const BaseLayout: FC<Props> = ({ children }) => {
  return (
    <>
      <SimpleNavBar />
      <Container size="md" mt={["14", "20"]} px="6">
        <FormRowProvider variant="onboarding">{children}</FormRowProvider>
      </Container>
    </>
  );
};
