import { useTranslation } from "@cx/react-polyglot";
import { Pagination } from "@cx/ui-pagination";
import { FC } from "react";
import { PaginationWithoutState } from "../hooks/use-pagination";

type Props = {
  pagination: PaginationWithoutState;
};

export const PaginationControls: FC<Props> = ({
  pagination: { prev, hasPrev, next, hasNext, numPages, page, to },
}) => {
  const { t } = useTranslation("common");
  return (
    <Pagination
      controls={{
        prev,
        next,
        to,
      }}
      current={page}
      total={numPages}
      hasNext={hasNext}
      hasPrev={hasPrev}
      justifyContent="center"
      prevText={t("prev")}
      nextText={t("next")}
    />
  );
};
