import { JobApplication, WorkTypes } from "@cx/feathers-client";
import {
  array,
  ArraySchema,
  bool,
  InferType,
  object,
  ObjectSchema,
  string,
} from "yup";
import {
  CompensationAmountSchema,
  CurrencySchema,
} from "../Onboarding/CandidateMoreDetails";

export const ApplicationSchema = object({
  job: string(),
  availableAt: string().required("myApplication.startDate.required"),
  resume: string().required("myApplication.resume.required"),
  skills: array()
    .ensure()
    .when("$skills", (deps, schema: ArraySchema<any, any, any, any>) => {
      const [skills] = deps as [string[]];

      if (skills.length) {
        return schema.of(string().required()).min(1, ({ label, min }) => ({
          key: "myApplication.skills.required",
          label,
          values: { min },
        }));
      }

      return schema;
    }),

  currency: CurrencySchema,
  compensation: object({})
    .required()
    .when("$workTypes", (deps, schema: ObjectSchema<any>) => {
      const [workTypes] = deps as [WorkTypes[]];

      for (const workType of workTypes) {
        schema = schema.shape({
          [workType]: CompensationAmountSchema,
        });
      }

      return schema;
    }),
}).when("$isPartner", (deps, schema: ObjectSchema<any>) => {
  const [isPartner] = deps as [boolean];

  if (!isPartner) {
    schema = schema.shape({
      consents: object({
        eligibility: bool().oneOf([true], "myApplication.eligibility.required"),
      }),
    });
  }

  return schema;
});

export type ApplicationSchema = InferType<typeof ApplicationSchema> &
  Pick<JobApplication, "consents">;
