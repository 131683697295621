import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "@cx/react-polyglot";
import { ApplicationHistoryTable } from "~/src/feature/Settings/ApplicationHistory/ApplicationHistoryTable";

export const ApplicationHistory: FC = () => {
  const { t } = useTranslation("settings.application");

  return (
    <>
      <Helmet title={t("pageTitle")} />
      <ApplicationHistoryTable />
    </>
  );
};
