import { taggedMessage } from "@cx/format";
import { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useCompanyHomeUrl } from "../hooks/use-company-urls";

const msg = taggedMessage("company-guard");

type Props = {
  children: ReactNode | ReactNode[];
};

export const CompanyGuard: FC<Props> = ({ children }) => {
  const route = useCompanyHomeUrl();

  if (route) {
    console.info(msg`Company url detected; navigating to %s...`, route);
    return <Navigate to={route} />;
  }

  return <>{children}</>;
};
