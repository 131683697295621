import { Box, Button, FormControl, Text } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FormError } from "@cx/ui-form";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { AiOutlineUpload } from "react-icons/ai";
import { useUploadResume } from "~/src/hooks/use-upload-resume";

export const ResumeUpload: FC = () => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation("candidate.resumeUpload", "myApplication");

  const { getRootProps, getInputProps, open } = useUploadResume((resume) => {
    setValue("resume", resume._id, { shouldValidate: true });
  });

  const error = errors.resume?.message;

  return (
    <Box mt="4" {...getRootProps()}>
      <FormControl isInvalid={!!error}>
        <FormError justifyContent="center">{error as string}</FormError>
      </FormControl>

      <input {...getInputProps()} />
      <Button
        onClick={open}
        variant="transparent"
        rightIcon={<AiOutlineUpload />}
        display="flex"
        mx="auto"
      >
        {t("upload")}
      </Button>
      <Text textAlign="center" mt="2">
        {t("limit")}
      </Text>
    </Box>
  );
};
