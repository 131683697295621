import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "./pages/404";
import { AuthRoute } from "./pages/Auth/routes";
import { DiversityDataRoute } from "./pages/DiversityData/routes";
import { JobRoute } from "./pages/Job/routes";
import { LegalsRoute } from "./pages/Legals/routes";
import { OnboardingRoute } from "./pages/Onboarding/routes";
import { SettingsRoute } from "./pages/Settings/routes";

export const RoutesCollection: FC = () => {
  return (
    <Routes>
      {/* Auth*/}
      {AuthRoute}

      {/* Diverisity Data */}
      {DiversityDataRoute}

      {/* Onboarding */}
      {OnboardingRoute}

      {/* Main routes */}
      {JobRoute}

      {/* Legals */}
      {LegalsRoute}

      {/* Settings*/}
      {SettingsRoute}

      {/* Not Found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
