import { useTranslation } from "@cx/react-polyglot";
import { RegisterForm } from "@cx/ui-features";
import { FC } from "react";
import { HeaderInfo } from "~/src/feature/HeaderInfo";

export const Register: FC = () => {
  const { t } = useTranslation("auth.register", "onboarding");

  return (
    <>
      <HeaderInfo
        heading={t("notApplied")}
        pageTitle={t("pageTitle")}
        step={t("step", { current: 1 })}
      />

      <RegisterForm redirect="/verification" login="/login" />
    </>
  );
};
