import { Divider } from "@chakra-ui/react";
import { WorkTypes } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { FormCheckbox, FormRow } from "@cx/ui-form";
import { FC } from "react";
import {
  CandidateAvailability,
  CandidateSkills,
  CandidateWorkType,
} from "../../Candidate";
import { ApplicationCurrency } from "./ApplicationCurrency";

type Props = {
  skills: string[];
  workTypes: WorkTypes[];
  showConsent: boolean;
};

export const ApplicationForm: FC<Props> = ({
  skills,
  workTypes,
  showConsent,
}) => {
  const { t } = useTranslation("myApplication");

  return (
    <>
      <FormRow>
        <CandidateAvailability />
      </FormRow>

      <FormRow direction="column">
        <ApplicationCurrency />
        {workTypes.map((workType) => (
          <CandidateWorkType workType={workType} key={`comp-${workType}`} />
        ))}
      </FormRow>

      {!!skills.length && (
        <FormRow>
          <CandidateSkills skills={skills} />
        </FormRow>
      )}

      {showConsent && (
        <>
          <Divider variant="dashed" />
          <FormRow my="3.5">
            <FormCheckbox field="consents.eligibility">
              {t("eligibility.title")}
            </FormCheckbox>
          </FormRow>
        </>
      )}
    </>
  );
};
