import { Container } from "@chakra-ui/react";
import { ErrorBoundedOutlet } from "@cx/ui-features";
import { NavItem, TabLinks } from "@cx/ui-tab-links";
import { FC } from "react";
import { useHomepageUrl } from "~/src/feature/Company";
import { GoBack } from "../GoBack";
import { useMenuItems } from "../Navbar/use-menu-items";
import { AuthGuard } from "./Guards";
import { LoadUserAndCandidate } from "./LoadUserAndCandidate";

export const SettingsLayout: FC = () => {
  const items: NavItem[] = useMenuItems().map((item) => ({
    name: item.label,
    path: item.href,
  }));

  const hompageUrl = useHomepageUrl();

  return (
    <AuthGuard>
      <LoadUserAndCandidate>
        <Container mb="20">
          <GoBack to={hompageUrl} />
          <TabLinks items={items} mt="6" />
          <ErrorBoundedOutlet />
        </Container>
      </LoadUserAndCandidate>
    </AuthGuard>
  );
};
