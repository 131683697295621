import { Button, Stack } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { NavLink } from "react-router-dom";

type Props = {
  link?: string;
  loading: boolean;
  initial?: boolean;
};

export const FormButton: FC<Props> = ({ link, loading, initial }) => {
  const { t } = useTranslation("common");

  return (
    <Stack
      spacing={["7", "9"]}
      direction={["column", "row"]}
      justify="center"
      my="8"
    >
      {!initial && (
        <Button
          as={NavLink}
          to={link || ""}
          variant="outline"
          mt={["4", 0]}
          aria-label="Back button"
        >
          {t("back")}
        </Button>
      )}

      <Button
        type="submit"
        order={["-1", "0"]}
        aria-label="Continue button"
        isLoading={loading}
        spinnerPlacement="start"
      >
        {t("continue")}
      </Button>
    </Stack>
  );
};
