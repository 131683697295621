import { Center } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FormCheckbox } from "@cx/ui-form";
import { FC } from "react";

export const CandidateAnonymizedDb: FC = () => {
  const { t } = useTranslation("candidate.aboutYourself");

  return (
    <Center w="full">
      <FormCheckbox field="anonDb" spacing="5" aria-label={t("anonDb")}>
        {t("anonDb")}
      </FormCheckbox>
    </Center>
  );
};
