import { Company } from "@cx/feathers-client";
import { useSelector } from "react-redux";
import { selectCandidate } from "~/src/state/slices/candidate-slice";
import { ApplicationGuard } from "../../partner.type";
import { TenantConfiguration } from "../tenant-config.type";
import { LocationAlert } from "./LocationAlert";

export const TalentNetApplicationGuard: ApplicationGuard = ({
  job,
  children,
}) => {
  const company: Company<TenantConfiguration> = job.company;
  const candidate = useSelector(selectCandidate);

  if (!company.partner?.metadata || !candidate.location?.country) {
    return null;
  }

  const { countryCodes } = company.partner.metadata;
  const { country } = candidate.location;

  if (!countryCodes.includes(country)) {
    console.warn(
      "[tn-form] Candidate location (%s) does not match country codes %o",
      country,
      countryCodes,
    );

    return <LocationAlert valid={countryCodes} />;
  }

  return <>{children}</>;
};
