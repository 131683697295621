import { taggedMessage } from "@cx/format";
import { bool, object, string } from "yup";
import { Company } from "@cx/feathers-client";
import { SchemaFactory } from "../partner.type";
import { Consent, TenantConfiguration } from "./tenant-config.type";

const msg = taggedMessage("tn-schema");

function ConsentSchemaFactory(consents: Consent[]) {
  let schema = object().required();

  for (const { name: key, required } of consents) {
    schema = schema.shape({
      [key]: required
        ? bool().oneOf([true], "partners.talentnet.consents.required")
        : bool(),
    });
  }

  return schema;
}

export const TalentNetSchemaFactory: SchemaFactory = (job) => {
  const company: Company<TenantConfiguration> = job.company;

  if (!company.partner?.metadata) {
    console.warn(
      msg`No metadata found for TN company %s (%s)`,
      company.name,
      company._id,
    );
    return object();
  }

  return object({
    partner: object({
      metadata: object({
        taxStatus: string().required().label("partners.talentnet.taxStatus"),
        consents: ConsentSchemaFactory(company.partner.metadata.consents),
      }),
    }),
  });
};
