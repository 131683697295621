import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { HeaderInfo } from "~/src/feature/HeaderInfo";
import { CandidateAboutYourself } from "~/src/feature/Onboarding/CandidateAboutYourself";

export const AboutYourself: FC = () => {
  const { t } = useTranslation("candidate.aboutYourself", "onboarding");

  return (
    <>
      <HeaderInfo
        heading={t("tellUs")}
        pageTitle={t("pageTitle")}
        step={t("step", { current: 3 })}
      />

      <CandidateAboutYourself />
    </>
  );
};
