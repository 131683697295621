import { taggedMessage } from "@cx/format";
import { FC, ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePrevious } from "react-use";
import { selectOnboarding } from "~/src/state/slices/candidate-slice";
import { GuardFactory } from "./GuardFactory";

const msg = taggedMessage("onboarding-guard");

function useShouldExitOnboarding() {
  const onboarding = useSelector(selectOnboarding);
  const prevOnboarding = usePrevious(onboarding);

  let shouldNavigate = false;

  // short circuit valid state changes (T -> F) from triggering a navigation
  const isValidStateChange =
    typeof prevOnboarding === "boolean" && prevOnboarding !== onboarding;

  if (onboarding === false) {
    if (isValidStateChange) {
      console.debug(
        msg`Detected a valid onboarding change (%o -> %o); preventing automatic navigation...`,
        prevOnboarding,
        onboarding,
      );
    } else {
      shouldNavigate = true;
    }
  }

  return shouldNavigate;
}

export const NotOnboardingGuard = GuardFactory(
  "onboarding",
  "/",
  useShouldExitOnboarding,
  "Onboarding is complete",
);
