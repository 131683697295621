import { Button } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectActiveResume } from "~/src/state/slices/candidate-slice";

type Props = {
  onClick: () => any;
};

export const SubmitButton: FC<Props> = ({ onClick }) => {
  const resume = useSelector(selectActiveResume);

  const { t } = useTranslation("myApplication");

  const {
    formState: { isSubmitting },
  } = useFormContext();

  if (!resume?.redacted) {
    return null;
  }

  return (
    <Button onClick={onClick} isLoading={isSubmitting}>
      {t("submit")}
    </Button>
  );
};
