import { Helmet } from "react-helmet-async";
import { useTranslation } from "@cx/react-polyglot";
import { SecurityForm } from "~/src/feature/Settings/Security/SecurityForm";

export const Security = () => {
  const { t } = useTranslation("settings.security");

  return (
    <>
      <Helmet title={t("pageTitle")} />
      <SecurityForm />
    </>
  );
};
