import { BoxProps } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { DividerText } from "@cx/ui-common";
import { FormRow } from "@cx/ui-form";
import { useToastStatus } from "@cx/ui-hooks";
import { useAppDispatch } from "@cx/ui-state";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ProfileSchema } from "~/src/feature/Settings/Profile/schema";
import { SaveButton } from "~/src/feature/Settings/SaveButton";
import {
  selectCandidate,
  updateCandidate,
} from "~/src/state/slices/candidate-slice";
import {
  CandidateAnonymizedDb,
  CandidateConsiderations,
  CandidateFirstName,
  CandidateIndustry,
  CandidateJobTitle,
  CandidateLastName,
  CandidateLocation,
  CandidateLocationPreferences,
  CandidatePhone,
  CandidateWorkTypeGroup,
} from "../../Candidate";

const halfRowWidth: BoxProps["width"] = ["full", null, null, "50%"];

export const ProfileForm: FC = () => {
  const toast = useToastStatus();
  const dispatch = useAppDispatch();

  const { t } = useTranslation("settings.profile");
  const { industry, ...candidate } = useSelector(selectCandidate);

  const methods = useForm<ProfileSchema>({
    resolver: yupResolver(ProfileSchema, { stripUnknown: true }),
    defaultValues: {
      ...candidate,
      industry: industry?._id,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  async function onSubmit({ ...payload }: ProfileSchema) {
    try {
      await dispatch(updateCandidate(payload)).unwrap();
      toast.success(t("success"));
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* basic candidate information */}
        <FormRow>
          <CandidateFirstName />
          <CandidateLastName />
        </FormRow>
        <FormRow>
          <CandidatePhone />
          <CandidateLocation />
        </FormRow>
        <FormRow>
          <CandidateConsiderations />
        </FormRow>
        <FormRow w={halfRowWidth}></FormRow>

        {/* role preferences */}
        <DividerText>{t("rolePreferences")}</DividerText>
        <FormRow>
          <CandidateJobTitle />
          <CandidateIndustry />
        </FormRow>
        <FormRow>
          <CandidateLocationPreferences />
        </FormRow>

        {/* compensation */}
        <DividerText>{t("compensation")}</DividerText>
        <FormRow w={halfRowWidth}>
          <CandidateWorkTypeGroup />
        </FormRow>

        {/* consents */}
        <DividerText>{t("consents")}</DividerText>
        <FormRow w={halfRowWidth}>
          <CandidateAnonymizedDb />
        </FormRow>

        <SaveButton isSubmitting={isSubmitting} />
      </form>
    </FormProvider>
  );
};
