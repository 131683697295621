import { Route } from "react-router-dom";
import { OnboardingLayout } from "~/src/components/Layouts";
import { ThankYou } from "./ThankYou";
import { AboutYourself } from "./AboutYourself";
import { DiversityStep1 } from "./DiversityStep1";
import { DiversityStep2 } from "./DiversityStep2";
import { MoreDetails } from "./MoreDetails";

export const OnboardingRoute = (
  <>
    <Route path="/onboarding" element={<OnboardingLayout />}>
      <Route path="about-yourself" element={<AboutYourself />} />
      <Route path="more-detail" element={<MoreDetails />} />
      <Route path="diversity-1" element={<DiversityStep1 />} />
      <Route path="diversity-2" element={<DiversityStep2 />} />
      <Route path="thank-you" element={<ThankYou />} />
    </Route>
  </>
);
