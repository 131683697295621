import { Helmet } from "react-helmet-async";
import { useTranslation } from "@cx/react-polyglot";
import { DiversityProfileForm } from "~/src/feature/Settings/Diversity/DiversityProfileForm";
import { DiversityOptionsProvider } from "~/src/feature/Diversity";

export const Diversity = () => {
  const { t } = useTranslation("settings.diversity");

  return (
    <>
      <Helmet title={t("pageTitle")} />
      <DiversityOptionsProvider>
        <DiversityProfileForm />
      </DiversityOptionsProvider>
    </>
  );
};
