import { useTranslation } from "@cx/react-polyglot";
import { FormInputPhone } from "@cx/ui-form";
import { FC } from "react";

export const CandidatePhone: FC = () => {
  const { t } = useTranslation("candidate.aboutYourself");

  return (
    <FormInputPhone
      title={t("phone.title")}
      autoComplete="tel"
      field="phone"
      placeholder={t("phone.placeholder")}
      type="tel"
      aria-label="Phone number"
    />
  );
};
