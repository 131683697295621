import { Checkbox } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import {
  Place,
  placeToAddress,
  useDropdownProps,
  useGooglePlaces,
} from "@cx/ui-form";
import { useChakraSelectProps } from "chakra-react-select";
import { FC } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { GroupBase, Props as ReactSelectProps } from "react-select";
import { useJobFilterContext } from "./JobFilterProvider";

export const LocationSearch: FC = () => {
  const { setPlace, setRemote, remote } = useJobFilterContext();
  const { defaultError, ...options } = useGooglePlaces();
  const { styles } = useDropdownProps();

  const { t } = useTranslation("job.filter");

  const chakraSelectProps = useChakraSelectProps({
    onChange(place: Place) {
      if (place?.value.place_id) {
        placeToAddress(place).then(setPlace);
      } else {
        setPlace(undefined);
      }
    },
    chakraStyles: styles,
    placeholder: t("location"),
    isClearable: true,
    "aria-label": t("location"),
  }) as ReactSelectProps<any, false, GroupBase<any>>;

  return (
    <>
      <GooglePlacesAutocomplete {...options} selectProps={chakraSelectProps} />

      <Checkbox
        isChecked={remote}
        onChange={(e) => setRemote(e.target.checked)}
      >
        {t("remote")}
      </Checkbox>
    </>
  );
};
