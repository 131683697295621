import { taggedMessage } from "@cx/format";
import { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";

type Props = {
  children: ReactNode | ReactNode[];
};

export function GuardFactory(
  name: string,
  route: string,
  useShouldNav: () => boolean,
  navMessage: string,
): FC<Props> {
  const msg = taggedMessage(`${name}-guard`);

  return ({ children }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const shouldNav = useShouldNav();

    if (shouldNav) {
      console.info(msg`${navMessage}; navigating to %s...`, route);
      return <Navigate to={route} />;
    }

    return <>{children}</>;
  };
}
