import { FormControl, FormLabel } from "@chakra-ui/react";
import { Industry, useFeathersService } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { dropdownStyles, FormError, useOptionLoader } from "@cx/ui-form";
import { Paginated } from "@feathersjs/feathers";
import { AsyncSelect } from "chakra-react-select";
import { FC } from "react";
import { useController } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectIndustry } from "~/src/state/slices/candidate-slice";

type Props = {
  required?: boolean;
  disabled?: boolean;
};

export const CandidateIndustry: FC<Props> = ({
  required = false,
  disabled = false,
}) => {
  const { t } = useTranslation("candidate.moreDetails");
  const selecdtedIndustry = useSelector(selectIndustry);
  const service = useFeathersService("industries");

  const {
    field: { onChange: setValue },
    fieldState: { error },
  } = useController({ name: "industry" });

  const searchIndustries = async (inputValue: string) => {
    const { data } = (await service.find({
      query: {
        name: { $search: inputValue },
        $limit: 50,
        $sort: {
          name: 1,
        },
      },
    })) as Paginated<Industry>;

    return data;
  };

  const optionLoader = useOptionLoader(searchIndustries, 350);

  return (
    <FormControl
      isInvalid={!!error}
      isRequired={required}
      isDisabled={disabled}
    >
      <FormLabel htmlFor="industry">{t("preferred.industry.title")}</FormLabel>

      <AsyncSelect<Industry>
        aria-label="Preferred Industry"
        defaultValue={selecdtedIndustry}
        onChange={(industry) => setValue(industry?._id)}
        loadOptions={optionLoader}
        chakraStyles={dropdownStyles}
        getOptionLabel={(industry) => industry.name}
        getOptionValue={(industry) => industry._id}
        defaultOptions
        inputId="industry-name"
      />
      <FormError>{error?.message}</FormError>
    </FormControl>
  );
};
