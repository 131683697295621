import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Button, Icon } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { useToastStatus } from "@cx/ui-hooks";
import { useAppDispatch } from "@cx/ui-state";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  redactResume,
  selectActiveResume,
} from "~/src/state/slices/candidate-slice";
import { InfoModal } from "./InfoModal";
import { Interstitial } from "./LoadingIndicator";

export const RedactButton: FC = () => {
  const resume = useSelector(selectActiveResume);
  const [showDialog, setShowDialog] = useState(false);
  const [ShowLoading, setShowLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { t } = useTranslation("myApplication");
  const toast = useToastStatus();

  const { setError } = useFormContext();

  const exit = () => {
    setShowDialog(false);
  };

  const redact = async () => {
    if (resume) {
      setShowLoading(true);
      try {
        await dispatch(redactResume(resume._id)).unwrap();
      } catch (error) {
        toast.error("An error occured when redacting the resume");
        console.error(error);
      }
      setShowLoading(false);
    } else {
      setError("resume", {
        message: "myApplication.resume.required",
      });
    }
  };

  // don't show this button if the resume is already redacted
  if (resume?.redacted?.key) {
    return null;
  }

  return (
    <>
      <Box pos="relative">
        <Button onClick={redact}>{t("redact")}</Button>
        <Icon
          as={InfoOutlineIcon}
          onClick={() => setShowDialog(true)}
          cursor="pointer"
          pos="absolute"
          top="-0.5rem"
          right="-1.5rem"
        />
      </Box>
      {showDialog && <InfoModal isOpen={showDialog} exit={exit} />}
      {ShowLoading && <Interstitial isOpen={ShowLoading} />}
    </>
  );
};
