import { chakra } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FormRow } from "@cx/ui-form";
import { useToastStatus } from "@cx/ui-hooks";
import { selectUser, updateUser, useAppDispatch } from "@cx/ui-state";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { SaveButton } from "../SaveButton";
import { MarketingPreferences } from "./MarketingPreferences";
import { MarketingPreferencesSchema } from "./marketing-schema";

export const MarketingForm: FC = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation("settings.marketing");

  const dispatch = useAppDispatch();
  const toast = useToastStatus();

  const methods = useForm<MarketingPreferencesSchema>({
    resolver: yupResolver(MarketingPreferencesSchema),
    defaultValues: {
      allowPromotions: user.allowPromotions,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  async function onSubmit(data: MarketingPreferencesSchema) {
    try {
      await dispatch(updateUser(data)).unwrap();
      toast.success(t("success"));
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
    }
  }

  return (
    <FormProvider {...methods}>
      <chakra.form onSubmit={handleSubmit(onSubmit)} pl="5">
        <FormRow>
          <MarketingPreferences />
        </FormRow>

        <SaveButton isSubmitting={isSubmitting} />
      </chakra.form>
    </FormProvider>
  );
};
