import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { GeoAddress } from "@cx/geocode-utils";
import { getJobQuery, JobQuery } from "./get-job-query";

type StringState = Dispatch<SetStateAction<string | undefined>>;

type FilterContext = {
  setSelectedCompany: StringState;
  setSearch: StringState;
  setPlace: Dispatch<SetStateAction<GeoAddress | undefined>>;
  setRemote: Dispatch<SetStateAction<boolean>>;
  focused: boolean;
  remote: boolean;
  query: JobQuery;
};

export const JobFilterContext = createContext<FilterContext>(
  {} as FilterContext,
);

type JobFilterProviderProps = {
  company?: string;
  children: ReactNode;
};

export const JobFilterProvider: FC<JobFilterProviderProps> = ({
  company,
  children,
}) => {
  const [selectedCompany, setSelectedCompany] = useState(company);
  const [search, setSearch] = useState<string>();
  const [place, setPlace] = useState<GeoAddress>();
  const [remote, setRemote] = useState(false);

  const filterContext = {
    setSelectedCompany,
    setSearch,
    setPlace,
    setRemote,
    focused: !!company,
    remote,
    query: getJobQuery(search, selectedCompany, place, remote),
  };

  return (
    <JobFilterContext.Provider value={filterContext}>
      {children}
    </JobFilterContext.Provider>
  );
};

export const useJobFilterContext = () => {
  return useContext(JobFilterContext);
};
