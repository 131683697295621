import { sleep } from "@cx/sleep";
import { fetchUser, selectIsAuthenticated, useAppDispatch } from "@cx/ui-state";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAsync } from "react-use";
import { fetchCandidate } from "~/src/state/slices/candidate-slice";

// We could use redux for this, but it is overkill for such a simple
// operation that is entirely local to this component.
const cache = {
  isLoaded: false,
};

/**
 * Fetch user and candidate data
 */
export function useFetchUserAndCandidate() {
  const dispatch = useAppDispatch();
  const isAuthed = useSelector(selectIsAuthenticated);

  const { loading, error } = useAsync(async () => {
    if (!isAuthed || cache.isLoaded) {
      return;
    }

    await Promise.all([
      dispatch(fetchCandidate()).unwrap(),
      dispatch(fetchUser()).unwrap(),
      sleep(200),
    ]);

    cache.isLoaded = true;
  }, [isAuthed]);

  // reset the cache when signing out
  useEffect(() => {
    if (!isAuthed) {
      cache.isLoaded = false;
    }
  }, [isAuthed]);

  return {
    loading,
    error,
  };
}
