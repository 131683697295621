import {
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { JobWithCompany } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { EditorContent } from "@cx/ui-common";
import { useEntityHydrator } from "@cx/ui-hooks";
import { DateTime } from "luxon";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { LoadingView } from "~/src/components/LoadingView";
import { CardApplyButton } from "~/src/feature/JobBoard/JobCard/CardApplyButton";
import { SkillsBadgeList } from "~/src/feature/JobBoard/JobCard/SkillsBadgeList";
import { SocialGroup } from "~/src/feature/JobBoard/SocialShare";
import { CompanyAvatar } from "../../feature/JobBoard/JobCard/CompanyAvatar";

export const JobDetail: FC = () => {
  const { t } = useTranslation("job.filter");

  const { data, isLoading } = useEntityHydrator("jobs", {
    $populate: "company",
  });

  if (isLoading || !data) {
    return <LoadingView />;
  }

  const job = data as unknown as JobWithCompany;

  return (
    <>
      <Helmet title={`${job.title} @ ${job.company.name}`} />
      <Container mt="10">
        <CompanyAvatar company={job.company} title={job.title} h="120" w="120">
          <Heading as="h2" fontWeight="bold" fontSize={["lg", "xl", "4xl"]}>
            {job.title}
          </Heading>
          <Text fontSize="lg" textTransform="capitalize">
            {job.company.name}
          </Text>
        </CompanyAvatar>

        {/* Worktype + Location */}
        <HStack mb="4" spacing={["2", "3"]} flexWrap={["wrap", null, "nowrap"]}>
          <Text fontSize="sm">
            {DateTime.fromISO(job.updatedAt).toRelative()}
          </Text>
          <Text as="span">&#x2022;</Text>
          <Text fontSize="sm" textTransform="capitalize">
            {job.workTypes.join(", ")}
          </Text>
          <Text as="span">&#x2022;</Text>
          <Text fontSize="sm" textTransform="capitalize">
            {job.location.formatted}
          </Text>
        </HStack>

        <Flex justify="space-between" align="center">
          <SkillsBadgeList skills={job.skills} />
        </Flex>
        <EditorContent py="6">{job.description}</EditorContent>
        <Stack
          align="center"
          justify={["center", "space-between"]}
          direction={["column", null, "row"]}
          spacing="3"
          mt="6"
          mb="2"
        >
          <CardApplyButton
            slug={job.company.slug}
            jobId={job._id}
            jobApplied={false}
          />
          <SocialGroup jobId={job._id} />
        </Stack>
      </Container>
    </>
  );
};
