import { AnySchema, ValidationError, lazy } from "yup";
type Lazy = ReturnType<typeof lazy>;

export const mapSchemaCompletion = <
  O extends object,
  M extends Record<string, AnySchema | Lazy>,
>(
  obj: O,
  schemaMap: M,
) => {
  type Sections = keyof M;

  const completionEntries = Object.entries(schemaMap).map(
    ([section, schema]): [Sections, boolean] => {
      let isValid = false;

      try {
        schema.validateSync(obj, { abortEarly: false });
        isValid = true;
      } catch (error) {
        if (error instanceof ValidationError) {
          const { errors, ...rest } = error;
          console.debug("[map-completion] Section `%s` is incomplete", section);
        }
      }

      return [section as Sections, isValid];
    },
  );

  return Object.fromEntries(completionEntries) as Record<Sections, boolean>;
};
