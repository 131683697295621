import { chakra } from "@chakra-ui/react";

export const ApplicationCard = chakra("div", {
  baseStyle: {
    p: "6",
    minH: "36",
    bg: "transparent",
    border: "1px",
    borderRadius: "lg",
    w: "full",
  },
});
