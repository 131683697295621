import { Center } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FormCheckbox } from "@cx/ui-form";
import { FC } from "react";

export const CandidateConsiderations: FC = () => {
  const { t } = useTranslation("candidate.aboutYourself");

  return (
    <Center w="full">
      <FormCheckbox
        field="considerations"
        spacing="5"
        aria-label={t("considerations")}
      >
        {t("considerations")}
      </FormCheckbox>
    </Center>
  );
};
