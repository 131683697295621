import { WorkTypes } from "@cx/feathers-client";
import { array, InferType, mixed, number, object, string } from "yup";
import { phraseFactory } from "@cx/react-polyglot";

const validationPhrases = phraseFactory("candidate.moreDetails.validation");
const preferredLabels = phraseFactory("candidate.moreDetails.preferred");

export const CompensationAmountSchema = number()
  .min(1)
  .label(validationPhrases("amount"))
  .required(validationPhrases("invalid"));

export const CurrencySchema = string().required(
  validationPhrases("noCurrency"),
);

export function CompensationSchemaFactory() {
  let schema = object({});

  WorkTypes.forEach((workType) => {
    schema = schema.when("workTypes", {
      is(value?: WorkTypes[]) {
        return Array.isArray(value) && value.includes(workType);
      },
      then(schema) {
        return schema.shape({
          [workType]: CompensationAmountSchema,
        });
      },
    });
  });

  return schema;
}

export const MoreDetailsSchema = object({
  title: string().required().label(preferredLabels("jobTitle.title")),
  industry: string().required().label(preferredLabels("industry.title")),
  workTypes: array()
    .of(
      mixed<WorkTypes>()
        .required()
        .oneOf([...WorkTypes]),
    )
    .min(1)
    .required()
    .label(preferredLabels("workType")),

  currency: CurrencySchema,
  compensation: CompensationSchemaFactory(),
});

export type MoreDetailsSchema = InferType<typeof MoreDetailsSchema>;
