import { Link } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { useAuthRedirect } from "~/src/feature/Auth/hooks/use-auth-redirect";

export const CreateProfileLink: FC = () => {
  const { t } = useTranslation("navbar");
  const jobDetailsPath = useMatch("jobs/:id");
  const { setApplicationUrl } = useAuthRedirect();

  function setRedirectUrl() {
    if (!jobDetailsPath || !jobDetailsPath.params.id) {
      return;
    }

    setApplicationUrl(jobDetailsPath.params.id);
  }

  return (
    <Link
      fontWeight="bold"
      as={NavLink}
      to="/register"
      onClick={setRedirectUrl}
    >
      {t("createProfile")}
    </Link>
  );
};
