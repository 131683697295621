import { Route } from "react-router-dom";
import { DefaultLayout } from "~/src/components/Layouts";
import { PrivacyPolicy } from "./PrivacyPolicy";

export const LegalsRoute = (
  <>
    <Route path="/" element={<DefaultLayout />}>
      <Route path="privacy" element={<PrivacyPolicy />} />
    </Route>
  </>
);
