import { useTranslation } from "@cx/react-polyglot";
import { Dropdown } from "@cx/ui-form";
import { FC } from "react";
import { useDiversityOptions } from "./DiversityOptionsProvider";

export const DiversitySocialClass: FC = () => {
  const { t } = useTranslation("diversity.step.two");
  const options = useDiversityOptions("social-class");

  return (
    <Dropdown
      aria-label="Social class"
      field="socialClass"
      isMulti
      isSearchable={false}
      options={options}
      title={t("background")}
    />
  );
};
