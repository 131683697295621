import { LocationPreferences } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { Dropdown } from "@cx/ui-form";
import { FC } from "react";

export const CandidateLocationPreferences: FC = () => {
  const { t } = useTranslation("candidate.aboutYourself");

  const locationPreferences = LocationPreferences.map((value) => ({
    value,
    label: t(`locationPreferences.${value}`),
  })).sort(({ label: a }, { label: b }) => {
    if (a === b) {
      return 0;
    }

    return a > b ? 1 : -1;
  });

  return (
    <Dropdown
      field="locationPreference"
      options={locationPreferences}
      title={t("locationPreference")}
      isSearchable={false}
      isMulti
      isClearable={false}
      aria-label="Location Preference"
    />
  );
};
