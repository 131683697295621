import { useSearchParams } from "react-router-dom";
import { CompanyUrls } from "./company-urls.types";
import { useCompanyUrls } from "./use-company-urls";

export function useSetCompanyUrls() {
  const { set } = useCompanyUrls();
  const [, setParams] = useSearchParams();

  return function (slug: string, returnUrl: string) {
    const urls: CompanyUrls = {
      home: `/company/${slug}`,
      partner: returnUrl,
    };

    console.info("[set-company-urls] Setting urls to", urls);
    set(urls);

    // clear search params
    setParams(
      {},
      {
        replace: true,
      },
    );
  };
}
