import { ErrorBoundedOutlet } from "@cx/ui-features";
import { useOnClear } from "@cx/ui-hooks";
import { FC, Fragment } from "react";
import { AuthGuard, EmailVerifiedGuard, NotOnboardingGuard } from "../Guards";
import { LoadUserAndCandidate } from "../LoadUserAndCandidate";
import { BaseLayout } from "./BaseLayout";

type Props = {
  clear?: boolean;
};

export const OnboardingLayout: FC<Props> = ({ clear = false }) => {
  useOnClear();

  const LayoutComponent = clear ? Fragment : BaseLayout;

  return (
    <AuthGuard>
      <LoadUserAndCandidate>
        <EmailVerifiedGuard>
          <NotOnboardingGuard>
            <LayoutComponent>
              <ErrorBoundedOutlet />
            </LayoutComponent>
          </NotOnboardingGuard>
        </EmailVerifiedGuard>
      </LoadUserAndCandidate>
    </AuthGuard>
  );
};
