import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Collapse,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { JobWithCompany } from "@cx/feathers-client";
import { EditorContent } from "@cx/ui-common";
import { motion } from "framer-motion";
import { DateTime } from "luxon";
import { FC } from "react";
import { CompanyAvatar } from "~/src/feature/JobBoard/JobCard/CompanyAvatar";
import { SocialGroup } from "../SocialShare";
import { CardApplyButton } from "./CardApplyButton";
import { JobCardContainer } from "./JobCardContainer";
import { SkillsBadgeList } from "./SkillsBadgeList";
import { WorkTypeRate } from "./WorkTypeRate";

type Props = {
  job: JobWithCompany;
};

export const JobCard: FC<Props> = ({
  job: {
    _id,
    company,
    currency,
    description,
    hasApplied,
    location,
    rates,
    remote,
    skills,
    startAt,
    title,
    workTypes,
  },
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <JobCardContainer onSpacebar={onToggle}>
      <Box w="full">
        <HStack justify="space-between" align="start">
          <CompanyAvatar company={company} title={title}>
            <Heading as="h2" fontWeight="bold" fontSize={["lg", null, "xl"]}>
              {title}
            </Heading>
            <Text fontSize="sm" textTransform="capitalize">
              {company.name}
            </Text>
          </CompanyAvatar>
          {remote && <Badge colorScheme="gray">Remote</Badge>}
        </HStack>

        {/* Worktype + Location */}
        <HStack
          mb="4"
          spacing={["2", "3"]}
          flexWrap={["wrap", null, "nowrap"]}
          divider={
            <Text as="span" mx="3">
              &#x2022;
            </Text>
          }
        >
          <Text fontSize="sm">{DateTime.fromISO(startAt).toRelative()}</Text>

          {workTypes.map((workType) => (
            <WorkTypeRate
              workType={workType}
              currency={currency || undefined}
              rateRange={rates[workType]}
              key={`rate-range-${workType}`}
            />
          ))}

          <Text fontSize="sm" textTransform="capitalize">
            {location.formatted}
          </Text>
        </HStack>

        <Flex justify="space-between" align="center">
          <SkillsBadgeList skills={skills} />

          <motion.div
            animate={{
              rotate: isOpen ? 180 : 0,
            }}
            transition={{ duration: 0.4 }}
          >
            <Icon
              as={ChevronDownIcon}
              fontSize={["2xl", "3xl"]}
              bg="primary.600"
              rounded="full"
              color="white"
              cursor="pointer"
              onClick={onToggle}
            />
          </motion.div>
        </Flex>
        <Collapse startingHeight={100} in={isOpen} animateOpacity>
          <EditorContent py="6">{description}</EditorContent>
        </Collapse>
        <Stack
          align="center"
          justify={["center", "space-between"]}
          direction={["column", null, "row"]}
          spacing="3"
          mt="6"
          mb="2"
        >
          <CardApplyButton
            slug={company.slug}
            jobId={_id}
            jobApplied={hasApplied}
          />
          <SocialGroup jobId={_id} />
        </Stack>
      </Box>
    </JobCardContainer>
  );
};
