import { useDisclosure } from "@chakra-ui/react";
import {
  Modals,
  selectModalStatus,
  setModalStatus,
  useAppDispatch,
} from "@cx/ui-state";
import { useSelector } from "react-redux";

export function useModalStatus(name: Modals) {
  const status = useSelector(selectModalStatus(name));
  const dispatch = useAppDispatch();

  const set = (status: boolean) => {
    dispatch(setModalStatus({ name, status }));
  };

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: status,
  });

  const confirm = () => {
    set(false);
    onClose();
  };

  return { isOpen, onClose, confirm };
}
