import { useTranslation } from "@cx/react-polyglot";
import { FormInput } from "@cx/ui-form";
import { FC } from "react";

export const CandidateLastName: FC = () => {
  const { t } = useTranslation("candidate.aboutYourself");
  return (
    <FormInput
      title={t("lastName")}
      field="lastName"
      type="text"
      autoComplete="family-name"
      aria-label={t("lastName")}
    />
  );
};
