import { RootState } from "@cx/ui-state";

export const selectCandidate = ({ candidate }: RootState) => candidate.value;

export const selectCandidateId = ({ candidate }: RootState) =>
  candidate.value._id;

export const selectCandidateDiversty = ({ candidate }: RootState) =>
  candidate.value.diversity;

export const selectIndustry = ({ candidate }: RootState) =>
  candidate.value.industry;

export const selectActiveResume = ({ candidate }: RootState) => {
  const resumes = candidate.value.resumes || [];
  const numResumes = resumes.length;

  return numResumes ? resumes[numResumes - 1] : undefined;
};

export const selectCandidateLoading = ({ candidate }: RootState) =>
  candidate.loading.candidate;

export const selectResumeLoading = ({ candidate }: RootState) =>
  candidate.loading.resume;

export const selectResumeError = ({ candidate }: RootState) =>
  candidate.error.resume;

export const selectOnboarding = ({ candidate }: RootState) =>
  candidate.value.onboarding === true;
