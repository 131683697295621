import { Box, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { ResendVerificationLink, useVerifyEmail } from "@cx/ui-features";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { AuthGuard } from "~/src/components/Layouts/Guards";
import { VerifyHeading } from "~/src/feature/Onboarding/VerifyHeading";

export const VerifyEmail: FC = () => {
  const { t } = useTranslation("auth.verification");
  const { message, loading, error, payload } = useVerifyEmail(
    "/onboarding/diversity-data",
  );

  return (
    <AuthGuard>
      <Helmet title={t("pageTitle")} />

      <VerifyHeading>
        <Text variant="cx-title">{t("verifying")}</Text>
        <Box pt="10">
          {loading && <Spinner label={t("verifying")} size="lg" />}
          {message}
        </Box>
      </VerifyHeading>

      {error && payload?.email && (
        <ResendVerificationLink email={payload.email} />
      )}
    </AuthGuard>
  );
};
