import { Route } from "react-router-dom";
import { DefaultLayout, SettingsLayout } from "~/src/components/Layouts";
import { ApplicationHistory } from "./ApplicationHistory";
import { Diversity } from "./Diversity";
import { Marketing } from "./Marketing";
import { Profile } from "./Profile";
import { Resume } from "./Resume";
import { Security } from "./Security";

export const SettingsRoute = (
  <>
    <Route path="/settings" element={<DefaultLayout />}>
      <Route element={<SettingsLayout />}>
        <Route index element={<Profile />} />
        <Route path="resume" element={<Resume />} />
        <Route path="application-history" element={<ApplicationHistory />} />
        <Route path="diversity" element={<Diversity />} />
        <Route path="security" element={<Security />} />
        <Route path="marketing" element={<Marketing />} />
      </Route>
    </Route>
  </>
);
