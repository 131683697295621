import { GetResource, ServiceNames, useFeathers } from "@cx/feathers-client";
import { waitAtLeast } from "@cx/sleep";
import { UnknownObject } from "@cx/types";
import { Paginated } from "@feathersjs/feathers";
import { useEffect, useRef, useState } from "react";
import { useAsyncFn, useCounter } from "react-use";

export const usePaginationFn = <
  S extends ServiceNames,
  Q extends UnknownObject,
>(
  service: S,
  initialQuery = {} as Q,
) => {
  const initialPageSize = (initialQuery.$limit as number) ?? 10;
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [page, { inc, dec, reset, set }] = useCounter(0, null, 0);
  const query = useRef(initialQuery);
  const app = useFeathers();

  type Resource = GetResource<S>;

  const [state, fetch] = useAsyncFn(
    async (query: UnknownObject = {}) => {
      return await waitAtLeast(
        () =>
          app.service(service).find({
            query: {
              ...query,
              $skip: page * pageSize,
              $limit: pageSize,
            },
          }) as Promise<Paginated<Resource>>,
        150,
      );
    },
    [page, pageSize],
  );

  // fetch on async function dependency change
  useEffect(() => {
    fetch(query.current);
  }, [fetch]);

  const total = state.value?.total ?? 0;
  const numPages = Math.ceil(total / (state.value?.limit ?? pageSize));
  const hasPrev = page > 0;
  const hasNext = page < numPages - 1;

  return {
    next: () => inc(),
    prev: () => dec(),
    to: set,
    reset() {
      if (hasPrev) {
        // triggers fetch automatically
        reset();
      } else {
        // manually call fetch
        fetch(query.current);
      }
    },
    page,
    pageSize,
    setPageSize,
    numPages,
    hasPrev,
    hasNext,
    ...state,
    query,
  };
};

export const usePagination = <S extends ServiceNames, Q extends UnknownObject>(
  service: S,
  initialQuery = {} as Q,
) => {
  const { query, ...pagination } = usePaginationFn(service, initialQuery);

  return pagination;
};

type Pagination = ReturnType<typeof usePagination<ServiceNames, any>>;
export type PaginationWithoutState = Omit<
  Pagination,
  "value" | "loading" | "error" | "reset" | "query"
>;
