import { Link } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";

type Props = {
  slug: string;
};

export const CompanyLink: FC<Props> = ({ slug }) => {
  const { t } = useTranslation("job.card");
  const { pathname } = useLocation();

  const profileUrl = `/company/${slug}`;

  if (pathname === profileUrl) {
    return null;
  }

  return (
    <Link as={NavLink} to={profileUrl}>
      {t("viewCompany")}
    </Link>
  );
};
