import { useTranslation } from "@cx/react-polyglot";
import { DiversityDetailsStep1 } from "~/src/feature/Onboarding/DiversityDetailsStep1";
import { HeaderInfo } from "~/src/feature/HeaderInfo";
import { DiversityOptionsProvider } from "~/src/feature/Diversity";

export const DiversityStep1 = () => {
  const { t } = useTranslation(
    "diversity.step.common",
    "diversity.step.one",
    "onboarding",
  );

  return (
    <>
      <HeaderInfo
        heading={t("heading")}
        pageTitle={t("pageTitle")}
        step={t("step", { current: 5 })}
        subheading={t("subheading")}
      />

      <DiversityOptionsProvider
        sections={[
          "ethnicity",
          "age-group",
          "gender",
          "sexual-orientation",
          "disability",
          "mental-health",
        ]}
      >
        <DiversityDetailsStep1 />
      </DiversityOptionsProvider>
    </>
  );
};
