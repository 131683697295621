import { object, string } from "yup";
import { PopulatedCandidate } from "@cx/feathers-client";
import { mapSchemaCompletion } from "~/lib/map-schema-completion";
import {
  DiversityStep1Schema,
  DiversityStep2Schema,
} from "~/src/feature/Diversity/diversity-schema";
import { AboutYourselfSchema } from "./CandidateAboutYourself/about-yourself-schema";
import { MoreDetailsSchema } from "./CandidateMoreDetails/more-details-schema";

const schemaMap = {
  about: AboutYourselfSchema,
  details: MoreDetailsSchema.shape({
    industry: object({
      name: string().required(),
    }),
  }),
  diversity1: object({
    diversity: DiversityStep1Schema,
  }),
  diversity2: object({
    diversity: DiversityStep2Schema,
  }),
} as const;

export type OnboardingProgress = Record<keyof typeof schemaMap, boolean>;

export const getOnboardingProgress = (candidate: PopulatedCandidate) =>
  mapSchemaCompletion(candidate, schemaMap);
