import { useTranslation } from "@cx/react-polyglot";
import { DiversityDetailsStep2 } from "~/src/feature/Onboarding/DiversityDetailsStep2";
import { HeaderInfo } from "~/src/feature/HeaderInfo";
import { DiversityOptionsProvider } from "~/src/feature/Diversity";

export const DiversityStep2 = () => {
  const { t } = useTranslation(
    "diversity.step.common",
    "diversity.step.one",
    "onboarding",
  );
  return (
    <>
      <HeaderInfo
        heading={t("heading")}
        pageTitle={t("pageTitle")}
        step={t("step", { current: 6 })}
        subheading={t("subheading")}
      />

      <DiversityOptionsProvider
        sections={["veteran", "care", "social-class", "faith"]}
      >
        <DiversityDetailsStep2 />
      </DiversityOptionsProvider>
    </>
  );
};
