import { Partners } from "@cx/types";
import { PartnerApplications } from "../partner-applications";
import {
  ApplicationGuard,
  CurrencyResolver,
  PartnerForm,
} from "../partner.type";
import { DefaultApplicationGuard } from "./DefaultApplicationGuard";

export interface PartnerConfig {
  PartnerForm?: PartnerForm;
  ApplicationGuard: ApplicationGuard;
  currencyResolver: CurrencyResolver;
}

export function usePartnerConfig(partner?: Partners) {
  const config: PartnerConfig = {
    ApplicationGuard: DefaultApplicationGuard,
    currencyResolver: () => [],
  };

  if (partner) {
    const { PartnerForm, ApplicationGuard, currencyResolver } =
      PartnerApplications[partner];
    config.PartnerForm = PartnerForm;

    if (ApplicationGuard) {
      config.ApplicationGuard = ApplicationGuard;
    }

    if (currencyResolver) {
      config.currencyResolver = currencyResolver;
    }
  }

  return config;
}
