import { GeoAddress } from "@cx/geocode-utils";

type SortDirections = 1 | -1;

type SortableProperties = {
  _id: SortDirections;
  createdAt: SortDirections;
  pinned: SortDirections;
  startAt: SortDirections;
};

export type JobQuery = {
  $search?: string;
  company?: string;
  $near?: {
    lat: number;
    lng: number;
  };
  $sort: Partial<SortableProperties>;
  $populate?: string | Record<string, unknown>;
  remote?: boolean;
};

export const getJobQuery = (
  $search?: string,
  company?: string,
  place?: GeoAddress,
  remote?: boolean,
) => {
  const query: JobQuery = {
    $sort: {
      // order matters here; don't reformat
      pinned: -1,
      startAt: -1,
      createdAt: -1,
      _id: -1,
    },
    $populate: "company",
  };

  if ($search) {
    query.$search = $search;
  }

  if (company) {
    query.company = company;
  }

  if (place) {
    const [lng, lat] = place.coordinates;
    query.$near = {
      lng,
      lat,
    };
  }

  if (remote) {
    query.remote = remote;
  }

  return query;
};
