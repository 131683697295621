import { Resume } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { useToastStatus } from "@cx/ui-hooks";
import { useAppDispatch } from "@cx/ui-state";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { addResume } from "~/src/state/slices/candidate-slice";

export function useUploadResume(cb = (resume: Resume) => {}) {
  const dispatch = useAppDispatch();
  const toast = useToastStatus();
  const { t } = useTranslation("candidate.resumeUpload");

  const onDrop = useCallback(async ([file]: File[]) => {
    const fd = new FormData();
    fd.append("resume", file);

    try {
      await dispatch(addResume(fd)).unwrap().then(cb);
    } catch (error) {
      if ((error as Error).name === "PayloadTooLarge") {
        toast.error(t("errorMessage"));
      }
      console.error(error);
    }
  }, []);

  return useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.oasis.opendocument.text": [".odt"],
    },
    onDrop,
    maxFiles: 1,
    multiple: false,
    useFsAccessApi: false,
  });
}
