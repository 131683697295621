import { Button, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { useTranslation } from "@cx/react-polyglot";

type Props = {
  isSubmitting: boolean;
};

export const SaveButton: FC<Props> = ({ isSubmitting }) => {
  const { t } = useTranslation("common");

  return (
    <Flex justify={["center", "end"]} my="5">
      <Button isLoading={isSubmitting} type="submit" aria-label="Save button">
        {t("save")}
      </Button>
    </Flex>
  );
};
