import {
  Box,
  CheckboxProps,
  Flex,
  HStack,
  Text,
  useCheckbox,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { FC } from "react";

export const WorkTypeCheckbox: FC<CheckboxProps> = ({ children, ...props }) => {
  const {
    state,
    getCheckboxProps,
    getInputProps,
    getLabelProps,
    getRootProps,
  } = useCheckbox(props);

  const styles = useMultiStyleConfig("WorkTypeCheckbox");

  return (
    <HStack as="label" align="center" cursor="pointer" {...getRootProps()}>
      <Text {...getLabelProps()}>{children}</Text>
      <input {...getInputProps()} />
      <Flex sx={styles.container} {...getCheckboxProps()}>
        <Box sx={styles.checkmark} opacity={state.isChecked ? 1 : 0} />
      </Flex>
    </HStack>
  );
};
