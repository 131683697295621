import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { useModalStatus } from "~/src/hooks/use-modal-status";
import { BecomeCxLogo } from "../BecomeCxLogo";

export const IntroModal: FC = () => {
  const { isOpen, confirm } = useModalStatus("jobIntro");
  const { t } = useTranslation("job.modal");
  const bg = useColorModeValue("lightMode", "darkMode");

  const close = () => {
    confirm();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      scrollBehavior="inside"
      size="lg"
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        pos="relative"
        position="relative"
        maxW="container.sm"
        m="5"
        px={["0", "4"]}
        py="6"
      >
        <ModalHeader>
          <Box>
            <BecomeCxLogo mb={["4", "6"]} />
            <Text fontSize={["lg", "3xl"]}>{t("title")} </Text>
          </Box>
        </ModalHeader>

        <ModalBody>
          <Box mb={["5", "10"]}>
            <Text fontWeight="bold" fontSize={["xl", "2xl"]} mb="4">
              {t("hiredFairly")}
            </Text>
            <Text mb="2" fontSize={["md", "lg"]}>
              {t("jobSeeking")}
            </Text>
            <Text mb="2" fontSize={["md", "lg"]}>
              {t("anonymously")}
            </Text>
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize={["xl", "2xl"]} mb="4">
              {t("diversityData")}
            </Text>
            <Text mb="2" fontSize={["md", "lg"]}>
              {t("identify")}
            </Text>
            <Text mb="2" fontSize={["md", "lg"]}>
              {t("personalised")}
            </Text>
          </Box>
        </ModalBody>
        <Box position="absolute" bottom="-0.1rem" right="0">
          <ModalCloseButton
            fontSize={["lg", "3xl"]}
            boxSize={[12, 16]}
            bg={bg}
            rounded="full"
            onClick={close}
          />
        </Box>
      </ModalContent>
    </Modal>
  );
};
