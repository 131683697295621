import { useTranslation } from "@cx/react-polyglot";
import { FormInput } from "@cx/ui-form";
import { FC } from "react";

export const CandidateFirstName: FC = () => {
  const { t } = useTranslation("candidate.aboutYourself");

  return (
    <FormInput
      title={t("firstName")}
      field="firstName"
      type="text"
      autoComplete="given-name"
      aria-label={t("firstName")}
    />
  );
};
