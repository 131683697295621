import { VStack } from "@chakra-ui/react";
import { WorkTypes } from "@cx/feathers-client";
import { FC } from "react";
import { useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCandidate } from "~/src/state/slices/candidate-slice";
import { CandidateCurrency } from "../CandidateCurrency";
import { CandidateWorkType } from "./CandidateWorkType";
import { WorkTypeCheckboxGroup } from "./WorkTypeCheckboxGroup";

export const CandidateWorkTypeGroup: FC = () => {
  const candidate = useSelector(selectCandidate);
  const workTypes: WorkTypes[] = useWatch({ name: "workTypes" });

  return (
    <VStack spacing={["6", "9"]} w="full">
      <WorkTypeCheckboxGroup defaultValue={candidate.workTypes} />
      <CandidateCurrency />

      {workTypes.map((workType) => (
        <CandidateWorkType workType={workType} key={`comp-${workType}`} />
      ))}
    </VStack>
  );
};
