import { Box, Image } from "@chakra-ui/react";
import { FC } from "react";

export const CxIcon: FC = () => {
  return (
    <Box boxSize={6}>
      <Image src="/img/logo-small.svg" alt="CandidateX Logo" rounded="full" />
    </Box>
  );
};
