import { HStack } from "@chakra-ui/react";
import { FC } from "react";
import { ApplyButton } from "./ApplyButton";
import { CompanyLink } from "./CompanyLink";

type Props = {
  slug: string;
  jobId: string;
  jobApplied: boolean;
};

export const CardApplyButton: FC<Props> = ({ slug, jobId, jobApplied }) => {
  return (
    <HStack spacing={["4", "6"]} alignItems="center">
      <ApplyButton applied={jobApplied} id={jobId} />
      <CompanyLink slug={slug} />
    </HStack>
  );
};
