import { Candidate } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { FormRow } from "@cx/ui-form";
import { useToastStatus } from "@cx/ui-hooks";
import { useAppDispatch } from "@cx/ui-state";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  selectCandidateDiversty,
  updateCandidateDiversity,
} from "~/src/state/slices/candidate-slice";
import {
  DiversityAgeGroup,
  DiversityCare,
  DiversityDisability,
  DiversityEthnicity,
  DiversityFaith,
  DiversityGender,
  DiversityMentalHealth,
  DiversitySexualOrientation,
  DiversitySocialClass,
  DiversityVeteran,
} from "../../Diversity";
import { SaveButton } from "../SaveButton";
import { DiversitySchema } from "./schema";

export const DiversityProfileForm: FC = () => {
  const candiateDiversity = useSelector(selectCandidateDiversty);
  const dispatch = useAppDispatch();
  const toast = useToastStatus();

  const { t } = useTranslation("settings.diversity");

  const methods = useForm<DiversitySchema>({
    resolver: yupResolver(DiversitySchema),
    defaultValues: candiateDiversity,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  async function onSubmit(data: Partial<Candidate["diversity"]>) {
    try {
      await dispatch(updateCandidateDiversity(data)).unwrap();
      toast.success(t("success"));
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <DiversityEthnicity />
          <DiversityMentalHealth />
        </FormRow>

        <FormRow>
          <DiversityAgeGroup />
          <DiversityFaith />
        </FormRow>

        <FormRow>
          <DiversitySexualOrientation />
          <DiversitySocialClass />
        </FormRow>

        <FormRow>
          <DiversityVeteran />
          <DiversityCare />
        </FormRow>

        <FormRow>
          <DiversityDisability />
          <DiversityGender />
        </FormRow>

        <SaveButton isSubmitting={isSubmitting} />
      </form>
    </FormProvider>
  );
};
