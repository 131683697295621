import {
  FormControl,
  FormLabel,
  HStack,
  useCheckboxGroup,
} from "@chakra-ui/react";
import { WorkTypes } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { FormError } from "@cx/ui-form";
import { FC } from "react";
import { useController } from "react-hook-form";
import { WorkTypeCheckbox } from "./WorkTypeCheckbox";

type Props = {
  defaultValue: WorkTypes[];
};

export const WorkTypeCheckboxGroup: FC<Props> = ({ defaultValue }) => {
  const { t } = useTranslation("candidate.moreDetails");

  const {
    fieldState: { error },
    field: { onChange },
  } = useController({ name: "workTypes" });

  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue,
    onChange,
  });

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor="location">{t("instructions")}</FormLabel>

      <HStack spacing="6">
        {WorkTypes.map((workType) => (
          <WorkTypeCheckbox
            aria-label={workType}
            id={workType}
            key={`checkbox-${workType}`}
            {...getCheckboxProps({ value: workType })}
          >
            {t(`${workType}.title`)}
          </WorkTypeCheckbox>
        ))}
      </HStack>
      <FormError>{error?.message}</FormError>
    </FormControl>
  );
};
