import { valuesToOptions } from "@cx/format";
import { useTranslation } from "@cx/react-polyglot";
import { useRegionConfig } from "@cx/react-region-config";
import { Dropdown } from "@cx/ui-form";
import { FC } from "react";
import { usePartnerCurrencies } from "../../PartnerApplications";

function useCurrencies() {
  const partnerCurrencies = usePartnerCurrencies();
  const { currencies: defaultCurrencies } = useRegionConfig();

  return partnerCurrencies.length ? partnerCurrencies : defaultCurrencies;
}

export const ApplicationCurrency: FC = () => {
  const { t } = useTranslation("candidate.moreDetails");
  const currencies = useCurrencies();

  return (
    <Dropdown
      field="currency"
      title={t("currency")}
      options={valuesToOptions(currencies)}
    />
  );
};
