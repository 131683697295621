import { feathersClient, handleUnauthenticated } from "@cx/feathers-client";
import { getRegion } from "@cx/region-config";
import io from "socket.io-client";

const { DEV, VITE_REGION, VITE_API } = globalThis.config;

export const app = feathersClient(VITE_API, {
  headers: {
    "x-region": getRegion(VITE_REGION, DEV),
  },
});

app.hooks({
  error: {
    all: [handleUnauthenticated()],
  },
});

export const socket = io(VITE_API, {
  withCredentials: true,
} as any);
