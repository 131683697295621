import { WorkTypes } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { useRegionConfig } from "@cx/react-region-config";
import { CompensationInput } from "@cx/ui-features";
import { FC } from "react";

export const CandidateWorkType: FC<{ workType: WorkTypes }> = ({
  workType,
}) => {
  const { t } = useTranslation("candidate.moreDetails");
  const { maxAmounts } = useRegionConfig();

  return (
    <CompensationInput
      field={`compensation.${workType}`}
      title={t(`${workType}.label`)}
      max={maxAmounts[workType]}
      aria-label={t(`${workType}.label`)}
    />
  );
};
