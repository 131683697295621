import { createContext } from "react";
import { CurrencyResolver } from "../partner.type";

export interface PartnerApplicationContext {
  currencies: string[];
}

export const PartnerApplicationContext = createContext(
  {} as PartnerApplicationContext,
);
