import { Container } from "@chakra-ui/react";
import { SimpleNavBar } from "@cx/ui-common";
import { ErrorBoundedOutlet } from "@cx/ui-features";
import { useOnClear } from "@cx/ui-hooks";
import { FC } from "react";
import { Disclaimer } from "../Disclaimer";

export const AuthLayout: FC = () => {
  useOnClear();

  return (
    <>
      <SimpleNavBar />
      <Container size="xs" mt={["4", "10"]}>
        <ErrorBoundedOutlet />
      </Container>
      <Disclaimer />
    </>
  );
};
