import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import debounce from "p-debounce";
import { FC, useCallback, useState } from "react";
import { IoClose, IoSearch } from "react-icons/io5";
import { useUpdateEffect } from "react-use";
import { useJobFilterContext } from "./JobFilterProvider";

export const JobSearch: FC = () => {
  const [search, setLocalSearch] = useState("");
  const { setSearch } = useJobFilterContext();

  const { t } = useTranslation("job.filter");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(
    debounce((value: string) => setSearch(value), 350),
    [],
  );

  useUpdateEffect(() => {
    onSearch(search);
  }, [onSearch, search]);

  const showClearButton = !!search.length;
  const clearSearch = () => setLocalSearch("");

  return (
    <InputGroup>
      <Input
        value={search}
        onChange={(e) => setLocalSearch(e.target.value)}
        placeholder={t("search")}
        aria-label={t("search")}
      />
      <InputRightElement>
        <IconButton
          icon={<Icon as={IoClose} fontSize="xl" />}
          mr="3"
          onClick={clearSearch}
          cursor="pointer"
          transition="all 0.2s"
          opacity={showClearButton ? "inherit" : "0"}
          tabIndex={showClearButton ? 0 : -1}
          aria-label={t("clear")}
          variant="ghost"
        />
        <Icon as={IoSearch} fontSize="xl" />
      </InputRightElement>
    </InputGroup>
  );
};
