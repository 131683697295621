import { ChakraProvider } from "@chakra-ui/react";
import { FeathersProvider } from "@cx/feathers-client";
import { Phrases } from "@cx/react-polyglot";
import {
  RegionAwareTranslationProvider,
  RegionConfigProvider,
} from "@cx/react-region-config";
import { FormProvider } from "@cx/ui-form";
import { theme } from "@cx/ui-theme";
import { FC, ReactNode, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { app } from "~/lib/feathers";
import { formConfig } from "~/lib/form-config";
import { RoutesCollection } from "./routes";
import config from "./seo";
import { persistor, store } from "./state";

import "./global-style.css";
import "./utils/sentry";

const Root: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <Helmet {...config} />
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider theme={theme}>
            <RegionConfigProvider>
              <RegionAwareTranslationProvider
                server={globalThis.config.VITE_L10N_HOST}
                app="candidate"
              >
                <FeathersProvider app={app}>
                  <FormProvider<Phrases> {...formConfig}>
                    <StrictMode>{children}</StrictMode>
                  </FormProvider>
                </FeathersProvider>
              </RegionAwareTranslationProvider>
            </RegionConfigProvider>
          </ChakraProvider>
        </PersistGate>
      </HelmetProvider>
    </Provider>
  );
};

const root = createRoot(document.getElementById("root")!);

root.render(
  <Root>
    <BrowserRouter>
      <RoutesCollection />
    </BrowserRouter>
  </Root>,
);
