import { FormRow } from "@cx/ui-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCandidateDiversty } from "~/src/state/slices/candidate-slice";
import { useUpdateDiversity } from "../Candidate/use-update-candidate";
import {
  DiversityCare,
  DiversityFaith,
  DiversitySocialClass,
  DiversityStep2Schema,
  DiversityVeteran,
} from "../Diversity";
import { FormButton } from "./FormButton";

export const DiversityDetailsStep2: FC = () => {
  const candiateDiversity = useSelector(selectCandidateDiversty);

  const methods = useForm<DiversityStep2Schema>({
    resolver: yupResolver(DiversityStep2Schema),
    defaultValues: candiateDiversity,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = useUpdateDiversity("/onboarding/thank-you");

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <DiversityVeteran />
          <DiversityCare />
        </FormRow>

        <FormRow>
          <DiversitySocialClass />
          <DiversityFaith />
        </FormRow>

        <FormButton link="/onboarding/diversity-1" loading={isSubmitting} />
      </form>
    </FormProvider>
  );
};
