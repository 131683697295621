import { useStorage } from "~/src/hooks/use-storage";
import { RedirectUrl } from "./redirect.types";

export function useLoginRedirect() {
  // useSessionStorage is broken so we have to rig up a custom one
  const { value, set } = useStorage<RedirectUrl>(sessionStorage, "after:login");

  return {
    loginRedirect: value,
    setLoginRedirect: set,
  };
}
