import { useCallback, useState } from "react";

function serialize(value: unknown) {
  if (typeof value === "string") {
    return value;
  }

  return JSON.stringify(value);
}

function deserialize(value: string | null) {
  if (value === null) {
    return;
  }

  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
}

export function useStorage<T>(storage: Storage, key: string) {
  const [value, write] = useState<T | undefined>(
    deserialize(storage.getItem(key)),
  );

  const set = useCallback(function (newValue: T) {
    if (newValue === null || newValue === undefined) {
      storage.removeItem(key);
    } else {
      storage.setItem(key, serialize(newValue));
    }

    write(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    value,
    set,
  };
}
