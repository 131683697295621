const addressMap = {
    address: { fields: ['street_number', 'route'] },
    city: { fields: ['locality'] },
    state: { fields: ['administrative_area_level_1'] },
    country: { fields: ['country'] },
};
const getComponentFactory = (components) => (placeField) => components.find(({ types }) => types.includes(placeField));
export const geoCodeToAddress = (components, formatted, lat, lng) => {
    const address = {
        formatted,
        coordinates: [lng, lat],
        type: 'Point',
    };
    const getComponent = getComponentFactory(components);
    for (const [addressField, mapping] of Object.entries(addressMap)) {
        const mappedValue = mapping.fields
            .map((placeField) => getComponent(placeField)?.short_name)
            .filter((v) => !!v)
            .join(' ');
        if (mappedValue) {
            address[addressField] = mappedValue;
        }
    }
    return address;
};
