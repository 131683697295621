import { phraseFactory } from "@cx/react-polyglot";
import { array, boolean, InferType, object, string } from "yup";

const stepOnePhrases = phraseFactory("diversity.step.one.labels");
const stepTwoPhrases = phraseFactory("diversity.step.two.labels");

const MultipleSelectionSchema = array()
  .of(string().required())
  .min(1)
  .required();

export const DiversityStep1Schema = object({
  ethnicity: MultipleSelectionSchema.label(stepOnePhrases("ethnicity")),
  ageGroup: string().required().label(stepOnePhrases("ageGroup")),
  gender: string().required().label(stepOnePhrases("gender")),
  sexualOrientation: string()
    .required()
    .label(stepOnePhrases("sexualOrientation")),
  disability: MultipleSelectionSchema.label(stepOnePhrases("disabilities")),
  mentalHealth: MultipleSelectionSchema.label(stepOnePhrases("mentalHealth")),
});

export const DiversityStep2Schema = object({
  veteran: string().required().label(stepTwoPhrases("veteran")),
  care: MultipleSelectionSchema.label(stepTwoPhrases("responsibilities")),
  faith: string().required().label(stepTwoPhrases("faith")),
  socialClass: MultipleSelectionSchema.label(stepTwoPhrases("background")),
});

export const DiversityOnboarding = object({
  readEthicalUse: boolean().oneOf(
    [true],
    "candidate.aboutYourself.confirmLocation",
  ),
});

export type DiversityStep1Schema = InferType<typeof DiversityStep1Schema>;
export type DiversityStep2Schema = InferType<typeof DiversityStep2Schema>;
