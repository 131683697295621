import { useTranslation } from "@cx/react-polyglot";
import { Dropdown } from "@cx/ui-form";
import { FC } from "react";

type Props = {
  skills: string[];
};

export const CandidateSkills: FC<Props> = ({ skills }) => {
  const { t } = useTranslation("myApplication");

  const options = skills.map((skill) => ({
    value: skill,
    label: skill,
  }));

  return (
    <Dropdown
      field="skills"
      options={options}
      title={t("skills.title")}
      isSearchable={false}
      isMulti
      isClearable={false}
    />
  );
};
