import { forwardRef, Image, ImageProps, useColorMode } from "@chakra-ui/react";

export const BecomeCxLogo = forwardRef<ImageProps, "img">((props, ref) => {
  const { colorMode } = useColorMode();
  const imgPath =
    colorMode == "light" ? "become-cx-dark_mode" : "become-cx-light_mode";

  return (
    <Image
      ref={ref}
      {...props}
      src={`/img/${imgPath}.svg`}
      alt="CanadidateX Logotype"
    />
  );
});
