import { Center, Container } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { GoBack } from "~/src/components/GoBack";
import { LoadingView } from "~/src/components/LoadingView";
import { ApplicationView } from "~/src/feature/Application/components/ApplicationView";
import { useJobWithCompany } from "~/src/feature/Application/hooks/use-job-with-company";
import { useAuthRedirect } from "~/src/feature/Auth/hooks/use-auth-redirect";
import { useHomepageUrl } from "~/src/feature/Company";
import { ApplicationHeader } from "./ApplicationHeader";

export const Application: FC = () => {
  const { t } = useTranslation("myApplication");
  const { id } = useParams();

  const { loading, error, value: job } = useJobWithCompany(id);

  const { clear } = useAuthRedirect();
  const hompageUrl = useHomepageUrl();

  useEffectOnce(() => {
    clear();
  });

  if (loading) {
    return <LoadingView />;
  }

  if (job?.hasApplied) {
    return (
      <Center minH="90vh">
        <ApplicationHeader job={job} />
      </Center>
    );
  }

  if (error || !job) {
    return null;
  }

  return (
    <>
      <Helmet title={t("title", { job: job.title })} />
      <Container>
        <GoBack to={hompageUrl} />
        <ApplicationHeader job={job} />
        <ApplicationView job={job} />
      </Container>
    </>
  );
};
