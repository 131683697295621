import { Box } from "@chakra-ui/react";
import { ErrorBoundedOutlet } from "@cx/ui-features";
import { useOnClear } from "@cx/ui-hooks";
import { FC } from "react";
import { ResolveCompanyUrls } from "~/src/feature/Company";
import { JobBoardFooter } from "../Footer";
import { DefaultNavbar } from "../Navbar/DefaultNavbar";
import { EmailVerifiedGuard, OnboardingGuard } from "./Guards";
import { LoadUserAndCandidate } from "./LoadUserAndCandidate";

export const DefaultLayout: FC = () => {
  useOnClear();

  return (
    <LoadUserAndCandidate>
      <ResolveCompanyUrls>
        <EmailVerifiedGuard>
          <OnboardingGuard>
            <DefaultNavbar />
            <Box flexGrow={1} as="main" minH="100vh">
              <ErrorBoundedOutlet />
            </Box>
            <JobBoardFooter />
          </OnboardingGuard>
        </EmailVerifiedGuard>
      </ResolveCompanyUrls>
    </LoadUserAndCandidate>
  );
};
