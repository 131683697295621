import { useTranslation } from "@cx/react-polyglot";
import { useMemo } from "react";
import {
  BsClockHistory,
  BsFileEarmarkPdf,
  BsFillLockFill,
  BsGear,
  BsMailbox,
} from "react-icons/bs";
import { CxIcon } from "./CxIcon";

const items = [
  {
    iconName: BsGear,
    altLabel: "profile.altLabel",
    label: "profile.pageTitle",
    href: "",
  },
  {
    iconName: BsFileEarmarkPdf,
    altLabel: "resume.altLabel",
    label: "resume.pageTitle",
    href: "resume",
  },
  {
    iconName: CxIcon,
    label: "diversity.pageTitle",
    href: "diversity",
  },
  {
    iconName: BsClockHistory,
    label: "application.pageTitle",
    href: "application-history",
  },
  {
    iconName: BsFillLockFill,
    label: "security.pageTitle",
    href: "security",
  },
  {
    iconName: BsMailbox,
    label: "marketing.pageTitle",
    href: "marketing",
  },
];

export function useMenuItems() {
  const { t } = useTranslation("settings");

  return useMemo(
    () =>
      items.map((item) => {
        const newItem = {
          ...item,
          label: t(item.label as any),
        };

        if (item.altLabel) {
          newItem.altLabel = t(item.altLabel as any);
        }

        return newItem;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
