import { PartnerApplicationConfig } from "../partner.type";
import { TalentNetForm } from "./components/TalentNetForm";
import { TalentNetApplicationGuard } from "./components/TalentNetApplicationGuard";
import { resolveTalentNetCurrency } from "./resolve-talentnet-currency";
import { TalentNetSchemaFactory } from "./talentnet.schema-factory";

export const talentnet: PartnerApplicationConfig = {
  schemaFactory: TalentNetSchemaFactory,
  PartnerForm: TalentNetForm,
  ApplicationGuard: TalentNetApplicationGuard,
  currencyResolver: resolveTalentNetCurrency,
};
