import { VStack } from "@chakra-ui/react";
import { FormRow, useFormRowProps } from "@cx/ui-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCandidate } from "~/src/state/slices/candidate-slice";
import {
  CandidateIndustry,
  CandidateJobTitle,
  CandidateWorkTypeGroup,
  useUpdateCandidate,
} from "../../Candidate";
import { FormButton } from "../FormButton";
import { MoreDetailsSchema } from "./more-details-schema";

export const CandidateDetail: FC = () => {
  const candidate = useSelector(selectCandidate);

  const methods = useForm<MoreDetailsSchema>({
    resolver: yupResolver(MoreDetailsSchema, { stripUnknown: true }),
    defaultValues: { ...candidate, industry: candidate.industry?._id },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = useUpdateCandidate("/onboarding/diversity-1");
  const { spacing } = useFormRowProps();

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <VStack spacing={spacing} w="full">
            <CandidateJobTitle />
            <CandidateIndustry />
          </VStack>
          <CandidateWorkTypeGroup />
        </FormRow>

        <FormButton link="/onboarding/about-yourself" loading={isSubmitting} />
      </form>
    </FormProvider>
  );
};
