import { Stack, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
};

export const FooterLink: FC<Props> = ({ title, children }) => {
  return (
    <Stack minW={48} color="white">
      <Text fontSize="sm" textTransform="uppercase" color="gray.500">
        {title}
      </Text>
      {children}
    </Stack>
  );
};
