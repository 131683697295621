import { Container } from "@chakra-ui/react";
import { FC } from "react";
import { JobFilterProvider, JobFilters } from "../JobFilters";
import { JobList } from "./JobList";

type Props = {
  focusedCompany?: string;
};

export const JobListContainer: FC<Props> = ({ focusedCompany }) => {
  return (
    <Container maxW="container.md">
      <JobFilterProvider company={focusedCompany}>
        <JobFilters />
        <JobList />
      </JobFilterProvider>
    </Container>
  );
};
