import { Center, Heading } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { InvalidRegion } from "@cx/ui-features";
import { FC, ReactElement } from "react";
import { LoadingView } from "../../LoadingView";
import { useFetchUserAndCandidate } from "./use-fetch-user-and-candidate";

type Props = {
  children?: ReactElement;
};

export const LoadUserAndCandidate: FC<Props> = ({ children }) => {
  const { loading, error } = useFetchUserAndCandidate();
  const { t } = useTranslation("onboarding");

  // shows a full screen loader
  if (loading) {
    return <LoadingView />;
  }

  // must come before the general catch-all for errors
  if (error?.message.startsWith("Invalid region")) {
    return <InvalidRegion error={error as any} />;
  }

  if (error) {
    console.error(error);
    return (
      <Center minH="100vh">
        <Heading textAlign="center">{t("errorMsg")}</Heading>
      </Center>
    );
  }

  return <>{children}</>;
};
