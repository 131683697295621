import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { useModalStatus } from "../hooks/use-modal-status";

export const Disclaimer: FC = () => {
  const { t } = useTranslation("common");

  const { isOpen, onClose, confirm } = useModalStatus("disclaimer");

  return (
    <Drawer
      isOpen={isOpen}
      placement="bottom"
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent alignItems="center" justifyContent="center" p="6">
        <Stack>
          <Box maxW="container.md" p="2" mb="2">
            <Text>{t("disclaimer")}</Text>
          </Box>

          <Stack
            spacing={4}
            direction={{ base: "column", md: "row" }}
            align="center"
            justify="center"
          >
            <Button onClick={confirm}>I agree</Button>
            <Button variant="outline" as="a" href="https://www.candidatex.co/">
              Exit Site
            </Button>
          </Stack>
        </Stack>
      </DrawerContent>
    </Drawer>
  );
};
