import { Alert, AlertIcon, Box, Flex, Stack } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { Loader } from "@cx/ui-common";
import {
  ResumeThumbnail,
  ResumeViewer,
  ResumeViewerProvider,
} from "@cx/ui-features";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import {
  selectActiveResume,
  selectCandidate,
} from "~/src/state/slices/candidate-slice";

export const Resume: FC = () => {
  const { t } = useTranslation("settings.resume", "common");

  const resume = useSelector(selectActiveResume);
  const candidate = useSelector(selectCandidate);

  if (!resume) {
    return (
      <>
        <Helmet title={t("pageTitle")} />
        <Alert status="info" mt="6" maxW="container.md" mx="auto">
          <AlertIcon />
          {t("noResume")}
        </Alert>
      </>
    );
  }

  return (
    <ResumeViewerProvider
      adobeId={globalThis.config.VITE_ADOBE_ID}
      initial="original"
      candidate={candidate._id}
      resume={resume._id}
    >
      <Helmet title={t("pageTitle")} />
      <Flex gap="3" direction={["column", null, "row"]}>
        <Stack flexBasis={[null, null, 64]} direction={["row", null, "column"]}>
          <ResumeThumbnail type="redacted" label={t("redacted")} />
          <ResumeThumbnail type="original" label={t("original")} />
        </Stack>

        <Box border="2px solid" w="full" minH="96">
          <ResumeViewer />
        </Box>
      </Flex>
    </ResumeViewerProvider>
  );
};
