import { useTranslation } from "@cx/react-polyglot";
import { Dropdown } from "@cx/ui-form";
import { FC } from "react";
import { useDiversityOptions } from "./DiversityOptionsProvider";

export const DiversityEthnicity: FC = () => {
  const { t } = useTranslation("diversity.step.one");
  const options = useDiversityOptions("ethnicity");

  return (
    <Dropdown
      field="ethnicity"
      options={options}
      title={t("ethnicity")}
      isSearchable={false}
      isMulti
      aria-label="Ethnicity"
    />
  );
};
