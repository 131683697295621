import { HStack, Icon, Text } from "@chakra-ui/react";
import { FC } from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { NavLink } from "react-router-dom";

type Props = {
  to: string;
};

export const GoBack: FC<Props> = ({ to }) => {
  return (
    <HStack
      as={NavLink}
      to={to}
      align="center"
      spacing="1"
      my={[4, 6]}
      display="inline-flex"
    >
      <Icon as={HiArrowNarrowLeft} />
      <Text as="span">Go Back</Text>
    </HStack>
  );
};
