import { taggedMessage } from "@cx/format";
import { selectIsAuthenticated } from "@cx/ui-state";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GuardFactory } from "./GuardFactory";

const msg = taggedMessage("auth-guard");

function useIsNotAuthed() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { pathname } = useLocation();

  useEffect(() => {
    console.debug(msg`The route %s is a protected route`, pathname);
    console.debug(msg`The user is authenticated: %o`, isAuthenticated);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, pathname]);

  return !isAuthenticated;
}

export const AuthGuard = GuardFactory(
  "auth",
  "/login",
  useIsNotAuthed,
  "User is not authenticated",
);
