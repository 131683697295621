import { Flex, Image, Spacer } from "@chakra-ui/react";
import { Company } from "@cx/feathers-client";
import { FC } from "react";
import { GoBack } from "~/src/components/GoBack";
import { useCompanyPartnerUrl } from "../hooks/use-company-urls";
import { CompanyLink } from "./CompanyLink";

type Props = {
  company: Company;
};

export const CompanyHeader: FC<Props> = ({ company }) => {
  const route = useCompanyPartnerUrl();

  return (
    <Flex direction={["column", "row"]} px="6">
      <Image
        border="2px"
        borderColor="white"
        borderRadius="full"
        display={{ base: "none", md: "flex" }}
        fallbackSrc={`https://avatars.dicebear.com/api/initials/${company.name}.svg`}
        fontSize="30"
        h="72"
        mt="-36"
        src={company.logo}
        w="72"
        zIndex="sticky"
      />
      <Flex
        align="center"
        flexGrow={1}
        ml={{ base: 0, md: "10" }}
        mt={{ base: "10" }}
      >
        <CompanyLink name={company.name} />
        <Spacer />
        <GoBack to={route} />
      </Flex>
    </Flex>
  );
};
