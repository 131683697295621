import { useTranslation } from "@cx/react-polyglot";
import { Dropdown } from "@cx/ui-form";
import { FC } from "react";
import { useDiversityOptions } from "./DiversityOptionsProvider";

export const DiversityFaith: FC = () => {
  const { t } = useTranslation("diversity.step.two");
  const options = useDiversityOptions("faith");

  return (
    <Dropdown
      aria-label="Faith"
      field="faith"
      isSearchable={false}
      options={options}
      title={t("faith")}
    />
  );
};
