import { useTranslation } from "@cx/react-polyglot";
import { ForgotPasswordForm } from "@cx/ui-features";
import { HeaderInfo } from "~/src/feature/HeaderInfo";

export const ForgotPassword = () => {
  const { t } = useTranslation("auth.password");
  return (
    <>
      <HeaderInfo
        heading={t("heading")}
        pageTitle={t("pageTitle")}
        subheading={t("getAnEmail")}
      />

      <ForgotPasswordForm />
    </>
  );
};
