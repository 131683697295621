import { boolean, InferType, string } from "yup";
import { AboutYourselfSchema } from "../../Onboarding/CandidateAboutYourself/about-yourself-schema";
import { MoreDetailsSchema } from "../../Onboarding/CandidateMoreDetails/more-details-schema";

export const ProfileSchema = AboutYourselfSchema.concat(
  MoreDetailsSchema,
).shape({
  anonDb: boolean().required().default(false),
});

export type ProfileSchema = InferType<typeof ProfileSchema>;
