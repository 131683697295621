import { Box, Divider, Link, Stack, Text } from "@chakra-ui/react";
import { useFeathersService } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { isFeathersError } from "@cx/types";
import {
  AuthConfirmPasswordInput,
  AuthEmailInput,
  AuthPasswordInput,
} from "@cx/ui-features";
import { FormRow } from "@cx/ui-form";
import { useToastStatus } from "@cx/ui-hooks";
import { clear, selectUser, updateUser, useAppDispatch } from "@cx/ui-state";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { SaveButton } from "../SaveButton";
import { SecuritySchema } from "./security-schema";

export const SecurityForm: FC = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation(
    "auth.register",
    "auth.login",
    "settings.security",
    "common",
  );
  const dispatch = useAppDispatch();
  const toast = useToastStatus();
  const service = useFeathersService("users");

  const methods = useForm<SecuritySchema>({
    resolver: yupResolver(SecuritySchema, { stripUnknown: true }),
    defaultValues: {
      email: user.email,
    },
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  async function onSubmit({
    confirmPassword,
    email,
    ...payload
  }: SecuritySchema) {
    try {
      await dispatch(updateUser(payload)).unwrap();
      toast.success(t("success"));
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
    }
  }

  const deleteAccount = async () => {
    const duration = 5000;

    try {
      await service.remove("me");
      dispatch(clear());

      toast.warn(t("accountDeleted"), {
        duration,
      });
    } catch (error) {
      if (isFeathersError(error)) {
        console.error(error.message);
      } else {
        console.error(error);
      }

      toast.error(t("deleteFailed"), {
        duration,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <AuthEmailInput disabled />
        </FormRow>
        <FormRow>
          <AuthPasswordInput current={false} />
          <AuthConfirmPasswordInput />
        </FormRow>

        <Divider pt="6" />
        <Stack
          pt="6"
          align="center"
          justify={["start", "space-between"]}
          direction={["column", null, "row"]}
        >
          <Box>
            <Text mb="1" fontWeight="semibold" textTransform="capitalize">
              {t("deleteAccount")}
            </Text>
            <Text color="gray.500">{t("deleteWarning")}</Text>
          </Box>

          <Link color="red" onClick={deleteAccount} pt={[5, null, 0]}>
            {t("deleteAccount")}
          </Link>
        </Stack>

        <Divider pb="6" />
        <SaveButton isSubmitting={isSubmitting} />
      </form>
    </FormProvider>
  );
};
