import * as Sentry from "@sentry/react";

const { host: apiHost } = new URL(globalThis.config.VITE_API);
const apiRegex = new RegExp(apiHost + "(?!/socket.io)");

Sentry.init({
  dsn: globalThis.config.VITE_SENTRY_KEY,
  integrations: [
    new Sentry.BrowserTracing({ tracePropagationTargets: [apiRegex] }),
  ],
  tracesSampleRate: 0.4,
  environment: globalThis.config.VITE_SENTRY_ENV,
});
