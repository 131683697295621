import { Center, Heading, Spinner } from "@chakra-ui/react";
import { JobWithCompany } from "@cx/feathers-client";
import { selectUser } from "@cx/ui-state";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useUpdateEffect } from "react-use";
import { PaginationControls } from "~/src/components/PaginationControls";
import { usePaginationFn } from "~/src/hooks/use-pagination";
import { useJobFilterContext } from "../JobFilters";
import { JobCard } from "./JobCard/JobCard";

export const JobList: FC = () => {
  const user = useSelector(selectUser);
  const { query } = useJobFilterContext();
  const {
    value,
    loading,
    reset,
    query: q,
    ...pagination
  } = usePaginationFn("jobs", query);

  // Update the query and reset the page
  useUpdateEffect(() => {
    q.current = query;
    reset();
  }, [query, user._id]);

  if (!value) {
    return null;
  }

  const jobs = value.data as unknown as JobWithCompany[];

  return (
    <>
      <Heading textAlign="center" mt="10">
        {value.total} Jobs
      </Heading>

      {loading ? (
        <Center my="10">
          <Spinner label="loading..." size="xl" />
        </Center>
      ) : (
        jobs.map((job) => <JobCard job={job} key={job._id} />)
      )}

      {/* pagination controls */}
      <Center my="8">
        <PaginationControls pagination={pagination} />
      </Center>
    </>
  );
};
