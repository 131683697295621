import { useTranslation } from "@cx/react-polyglot";
import { FormInput } from "@cx/ui-form";
import { FC } from "react";

export const CandidateJobTitle: FC = () => {
  const { t } = useTranslation("candidate.moreDetails");

  return (
    <FormInput
      field="title"
      title={t("preferred.jobTitle.title")}
      type="text"
      placeholder={t("preferred.jobTitle.placeholder")}
      autoComplete="organization-title"
      aria-label="Preferred job title"
    />
  );
};
