import { InferType } from "yup";

import {
  DiversityStep1Schema,
  DiversityStep2Schema,
} from "../../Diversity/diversity-schema";

export const DiversitySchema =
  DiversityStep1Schema.concat(DiversityStep2Schema);

export type DiversitySchema = InferType<typeof DiversitySchema>;
