import { Route } from "react-router-dom";
import { AuthLayout } from "~/src/components/Layouts";
import { ForgotPassword } from "./ForgotPassword";
import { Login } from "./Login";
import { Register } from "./Register";
import { ResetPassword } from "./ResetPassword";
import { VerifyEmail } from "./VerifyEmail";
import { VerifyEmailPrompt } from "./VerifyEmailPrompt";

export const AuthRoute = (
  <>
    <Route path="/" element={<AuthLayout />}>
      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />

      <Route path="verification">
        <Route index element={<VerifyEmailPrompt />} />
        <Route path=":token" element={<VerifyEmail />} />
      </Route>

      <Route path="reset-password">
        <Route index element={<ForgotPassword />} />
        <Route path=":token" element={<ResetPassword />} />
      </Route>
    </Route>
  </>
);
