import { useTranslation } from "@cx/react-polyglot";
import { ResetPasswordForm } from "@cx/ui-features";
import { HeaderInfo } from "~/src/feature/HeaderInfo";

export const ResetPassword = () => {
  const { t } = useTranslation("auth.resetPassword");
  return (
    <>
      <HeaderInfo heading={t("heading")} pageTitle={t("pageTitle")} />

      <ResetPasswordForm login="/login" redirect="/onboarding/about-yourself" />
    </>
  );
};
