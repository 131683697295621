import { useTranslation } from "@cx/react-polyglot";
import { LoginForm } from "@cx/ui-features";
import { FC } from "react";
import { useLoginRedirect } from "~/src/feature/Auth/hooks/use-login-redirect";
import { useHomepageUrl } from "~/src/feature/Company";
import { HeaderInfo } from "~/src/feature/HeaderInfo";

export const Login: FC = () => {
  const { t } = useTranslation("auth.login");
  const { loginRedirect } = useLoginRedirect();
  const hompageUrl = useHomepageUrl();

  return (
    <>
      <HeaderInfo heading={t("heading")} pageTitle={t("pageTitle")} />
      <LoginForm
        redirect={loginRedirect ?? hompageUrl}
        forgot="/reset-password"
        register="/register"
      />
    </>
  );
};
