import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "@cx/react-polyglot";
import { IntroModal } from "~/src/feature/JobBoard/IntroModal";
import { JobListContainer } from "~/src/feature/JobBoard/JobListContainer";

import { Heading } from "@chakra-ui/react";

export const JobBoard: FC = () => {
  const { t } = useTranslation("job.filter");

  return (
    <>
      <Helmet title={t("pageTitle")} />
      <Heading textAlign="center" mb="10">
        Jobs
      </Heading>

      <JobListContainer />
      <IntroModal />
    </>
  );
};
