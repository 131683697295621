import { FormRow } from "@cx/ui-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCandidate } from "~/src/state/slices/candidate-slice";
import {
  CandidateConsiderations,
  CandidateFirstName,
  CandidateLastName,
  CandidateLocation,
  CandidateLocationPreferences,
  CandidatePhone,
  useUpdateCandidate,
} from "../../Candidate";
import { FormButton } from "../FormButton";
import { AboutYourselfSchema } from "./about-yourself-schema";

export const CandidateAboutYourself: FC = () => {
  const candidate = useSelector(selectCandidate);

  const methods = useForm<AboutYourselfSchema>({
    resolver: yupResolver(AboutYourselfSchema, { stripUnknown: true }),
    defaultValues: { ...candidate },
  });

  const onSubmit = useUpdateCandidate("/onboarding/more-detail");

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <CandidateFirstName />
          <CandidateLastName />
        </FormRow>

        <FormRow>
          <CandidatePhone />
          <CandidateLocation />
        </FormRow>

        <FormRow>
          <CandidateLocationPreferences />
          <CandidateConsiderations />
        </FormRow>

        <FormButton loading={isSubmitting} initial />
      </form>
    </FormProvider>
  );
};
