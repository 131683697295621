import { HStack, Icon, useClipboard } from "@chakra-ui/react";
import { useToastStatus } from "@cx/ui-hooks";
import { FC } from "react";
import { HiShare } from "react-icons/hi";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { useEffectOnce } from "react-use";

type Props = {
  jobId: string;
};

export const SocialGroup: FC<Props> = ({ jobId }) => {
  const { onCopy, setValue, value: link } = useClipboard("");
  const toast = useToastStatus();

  useEffectOnce(() => {
    const link = [globalThis.config.VITE_JOB_URL, "share", jobId];
    setValue(link.join("/"));
  });

  const shareQuote = `Check out this job from CandidateX!`;

  const shareLink = () => {
    toast.success("Link copied to clipboard");
    onCopy();
    console.debug("[social-share] Copied job link (%s)", link);
  };

  return (
    <HStack spacing="4" align="center">
      <FacebookShareButton url={link} quote={shareQuote}>
        <Icon as={FacebookIcon} fontSize="30" round verticalAlign="middle" />
      </FacebookShareButton>
      <TwitterShareButton title={shareQuote} url={link}>
        <Icon as={TwitterIcon} fontSize="30" round verticalAlign="middle" />
      </TwitterShareButton>
      <LinkedinShareButton title={shareQuote} summary={shareQuote} url={link}>
        <Icon as={LinkedinIcon} fontSize="30" round verticalAlign="middle" />
      </LinkedinShareButton>

      <Icon as={HiShare} cursor="pointer" fontSize="20" onClick={shareLink} />
    </HStack>
  );
};
