import { Center, Heading, Link, Stack, Text } from "@chakra-ui/react";
import { JobWithCompany } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCandidate } from "~/src/state/slices/candidate-slice";
import { PartnerApplicationProvider } from "../../PartnerApplications";
import { useApplicationForm } from "../hooks/use-application-form";
import { useApplicationSubmit } from "../hooks/use-application-submit";
import { ApplicationCard } from "./ApplicationCard";
import { ApplicationForm } from "./ApplicationForm";
import { RedactButton } from "./RedactButton";
import { ResumePreview } from "./ResumePreview";
import { ResumeUpload } from "./ResumeUpload";
import { SubmitButton } from "./SubmitButton";

type Props = { job: JobWithCompany };

export const ApplicationView: FC<Props> = ({ job }) => {
  const candidate = useSelector(selectCandidate);

  const { t } = useTranslation("myApplication");

  const form = useApplicationForm(job);
  const submit = useApplicationSubmit();

  return (
    <FormProvider {...form}>
      <form>
        <Stack
          direction={["column", null, "row"]}
          spacing={["4", "6"]}
          mb={["10", "16"]}
        >
          <ApplicationCard maxW={["full", null, "lg"]}>
            <Heading mb="10" fontSize="2xl">
              {t("title", { job: job.title })}
            </Heading>
            <Text fontWeight="bold" fontSize="xl" mb="8">
              {candidate.firstName} {candidate.lastName}
            </Text>
            <ResumePreview />
            <ResumeUpload />
          </ApplicationCard>
          <ApplicationCard>
            <PartnerApplicationProvider job={job}>
              <ApplicationForm
                skills={job.skills}
                workTypes={job.workTypes}
                showConsent={!job.partner?.ref}
              />
            </PartnerApplicationProvider>
          </ApplicationCard>
        </Stack>
        {/* submit buttons */}
        <Center pb="82px" flexDirection="column">
          <RedactButton />
          <SubmitButton onClick={form.handleSubmit(submit)} />
          <Link
            mt="10"
            href="https://www.candidatex.co/candidatex-web-application-feedback/"
            isExternal
          >
            {t("contactUs")}
          </Link>
        </Center>
      </form>
    </FormProvider>
  );
};
