import { useTranslation } from "@cx/react-polyglot";
import { Dropdown } from "@cx/ui-form";
import { FC } from "react";
import { useDiversityOptions } from "./DiversityOptionsProvider";

export const DiversityCare: FC = () => {
  const { t } = useTranslation("diversity.step.two");
  const options = useDiversityOptions("care");

  return (
    <Dropdown
      aria-label="Care responsibilities"
      field="care"
      isSearchable={false}
      options={options}
      title={t("responsibilities")}
      isMulti
    />
  );
};
