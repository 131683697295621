import { Heading, Link, Stack, Text } from "@chakra-ui/react";
import { JobWithCompany } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { NavLink } from "react-router-dom";

type Props = {
  job: JobWithCompany;
};

export const ApplicationHeader: FC<Props> = ({ job }) => {
  const { t } = useTranslation("myApplication");

  const translationSwap = job.hasApplied ? "applied" : "apply";

  return (
    <Stack align="center" my="4">
      <Heading
        textAlign="center"
        maxW="container.md"
        fontSize={["xl", "3xl"]}
        mb="6"
      >
        {t(translationSwap)} {""}
        <Text as="span" colorScheme="highlight">
          {job.title}
        </Text>{" "}
        role at{" "}
        <Text as="span" borderBottom="3px dashed">
          {job.company.name}
        </Text>
      </Heading>

      {job.hasApplied && (
        <Link as={NavLink} to="/settings/application-history">
          Go to application history
        </Link>
      )}
    </Stack>
  );
};
