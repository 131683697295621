import {
  Box,
  Button,
  Checkbox,
  Flex,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { useSupportsAvif } from "@cx/supports-img";
import { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { BecomeCxLogo } from "~/src/feature/BecomeCxLogo";
import style from "./Loading.module.css";

const styles = (...names: string[]) =>
  names.map((name) => style[name]).join(" ");

export const DiversityData: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("diversity.onboarding", "common");

  const [checked, setChecked] = useState(false);

  const bg = useColorModeValue("lightMode", "darkMode");
  const supports = useSupportsAvif("avif", "jpg");

  async function onSubmit() {
    navigate("/onboarding/about-yourself");
  }

  if (supports === undefined) {
    return null;
  }

  return (
    <>
      <Helmet title={t("pageTitle")} />
      <Box className={styles("hero", supports)} />
      <BecomeCxLogo
        left="4"
        maxW={["xs", "md"]}
        pos="absolute"
        right="4"
        top={["6", "12"]}
      />

      <Flex
        alignItems={{ base: "flex-start", lg: "center" }}
        direction={{ base: "column", lg: "row" }}
        h={["full", "100vh"]}
        justifyContent={["center", "space-between"]}
        pt={{ base: "10", lg: "0" }}
        w="full"
      >
        {/* First Column */}
        <Flex
          direction="column"
          flex={{ base: 0, lg: 1 }}
          maxW="container.xl"
          mx="auto"
          px={["2.5	", "12"]}
          py="12"
          zIndex="10"
        >
          <Box
            fontSize={["2xl", "4xl	", "6xl"]}
            fontWeight="Bold"
            pt={["10", null, "32"]}
            textAlign="center"
          >
            <Text variant="opaque" maxW="container.lg" mx="auto">
              {t("titleOne")}
            </Text>
            <Text variant="opaque" maxW="container.md" mx="auto" my="2">
              {t("titleTwo")}
            </Text>
          </Box>
        </Flex>
        {/* Second Column */}
        <Flex
          bg={bg}
          direction="column"
          flex={1}
          h="100vh"
          justify={{ base: "start", lg: "center" }}
          maxW={["auto", "container.xl"]}
          px="8"
          py="16"
          w="full"
          zIndex={10}
        >
          <Box fontSize={["lg", "2xl"]} maxW="container.sm" mx="auto">
            <Text>{t("ethicalData")}</Text> <br />
            <Text>{t("understanding")}</Text> <br />
            <Text>{t("goal")}</Text> <br />
            <Text>{t("section")}</Text> <br />
            <Text>{t("future")}</Text> <br />
            <Text>{t("identify")}</Text> <br />
            <Text>{t("completeControl")}</Text> <br />
          </Box>
          <VStack
            pt={[0, "10"]}
            spacing="18px"
            verticalAlign="baseline"
            w="full"
          >
            <Checkbox
              fontWeight="bold"
              isChecked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              size="lg"
              tabIndex={1}
            >
              {t("readAbove")}
            </Checkbox>
            <Button
              isDisabled={!checked}
              onClick={onSubmit}
              tabIndex={2}
              type="submit"
            >
              {t("continueToSignUp")}
            </Button>
          </VStack>
        </Flex>
      </Flex>
    </>
  );
};
