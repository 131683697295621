import { Company, useFeathersService } from "@cx/feathers-client";
import { Paginated } from "@feathersjs/feathers";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { LoadingView } from "~/src/components/LoadingView";
import { CompanyJobList } from "~/src/feature/Company";

export const CompanyProfile: FC = () => {
  const { id } = useParams();
  const service = useFeathersService("companies");

  const { loading, value } = useAsync(async () => {
    if (!id) {
      return;
    }

    return (await service.find({
      query: {
        slug: id,
      },
    })) as Paginated<Company>;
  }, []);

  if (loading) {
    return <LoadingView />;
  }

  const company = value?.data[0];

  if (!company) {
    return null;
  }

  return (
    <>
      <Helmet title={`Company Profile`} />
      <CompanyJobList company={company} />
    </>
  );
};
