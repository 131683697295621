import { useFeathersService } from "@cx/feathers-client";
import { waitAtLeast } from "@cx/sleep";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { useSocket } from "~/src/hooks/use-socket";
import {
  selectActiveResume,
  selectCandidate,
  selectResumeError,
  selectResumeLoading,
} from "~/src/state/slices/candidate-slice";

export function useResumeThumbnail() {
  const candidate = useSelector(selectCandidate);
  const resume = useSelector(selectActiveResume);
  const thunkLoading = useSelector(selectResumeLoading);
  const thunkError = useSelector(selectResumeError);

  const service = useFeathersService("candidates");
  const socket = useSocket();

  const [url, setUrl] = useState<string>();
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (thunkError) {
      setIsNew(false);
    }
  }, [thunkError]);

  useEffect(() => {
    if (thunkLoading) {
      setIsNew(true);
    }
  }, [thunkLoading]);

  useEffectOnce(() => {
    socket.on("thumbnail:completed", () => {
      setIsNew(false);
    });

    socket.on("thumbnail:failed", () => {
      setIsNew(false);
    });

    return () => {
      socket.off("thumbnail:completed");
      socket.off("thumbnail:failed");
    };
  });

  useEffect(() => {
    if (resume?._id) {
      waitAtLeast(() =>
        service.getResume(candidate._id, resume._id, { $link: "thumb" }),
      ).then(({ url }) => {
        const formattedUrl = new URL(url).toString();
        setUrl(formattedUrl);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resume?._id, resume?.redacted]);

  return {
    isNew,
    url,
  };
}
