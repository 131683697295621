import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { actionMatcherFactory } from "@cx/action-matcher";
import { PopulatedCandidate, Resume } from "@cx/feathers-client";
import {
  getOnboardingProgress,
  OnboardingProgress,
} from "~/src/feature/Onboarding/get-onboarding-progress";
import { RootState } from "@cx/ui-state";

export * from "./candidate.selectors";
export * from "./candidate.thunks";

export type CandidateState = {
  value: PopulatedCandidate;
  loading: {
    candidate: boolean;
    resume: boolean;
  };
  error: {
    candidate: boolean;
    resume: boolean;
  };
  onboardingProgress: OnboardingProgress;
};

const slice = createSlice({
  name: "candidate",
  initialState: {
    value: {
      diversity: {},
    },
    loading: {
      candidate: false,
      resume: false,
    },
    error: {
      candidate: false,
      resume: false,
    },
    onboardingProgress: {},
  } as CandidateState,
  reducers: {
    setAvailableAt: (state, { payload }: PayloadAction<string>) => {
      state.value.availableAt = payload;
    },
  },
  extraReducers(builder) {
    const matcher = actionMatcherFactory(builder);

    matcher("resume", (state, payload: Resume) => {
      const currentIndex = state.value.resumes.findIndex(
        (resume) => resume._id === payload._id,
      );

      if (currentIndex === -1) {
        const resumes = state.value.resumes.slice(-2);
        resumes.push(payload);
        state.value.resumes = resumes;
      } else {
        state.value.resumes[currentIndex] = payload;
      }
    });

    matcher("candidate", (state, payload: PopulatedCandidate) => {
      state.value = payload;
      state.onboardingProgress = getOnboardingProgress(payload);
    });
  },
});

export const { setAvailableAt } = slice.actions;

export default slice.reducer;
