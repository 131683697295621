import { useTranslation } from "@cx/react-polyglot";
import { FormCheckbox } from "@cx/ui-form";
import { FC } from "react";

export const MarketingPreferences: FC = () => {
  const { t } = useTranslation("settings.marketing", "auth.register");

  return (
    <FormCheckbox field="allowPromotions" variant="compressed">
      {t("confirm.promotional")}
    </FormCheckbox>
  );
};
