import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Link, Text } from "@chakra-ui/react";
import { FC } from "react";

type Props = {
  name: string;
  url?: string;
};

export const CompanyLink: FC<Props> = ({ name, url }) => {
  return (
    <Box>
      <Text fontSize="3xl" fontWeight="bold">
        {name}
        <Text as="span" ml="6" fontSize="lg">
          {url && (
            <Link href={url} isExternal>
              <ExternalLinkIcon /> Website
            </Link>
          )}
        </Text>
      </Text>
    </Box>
  );
};
