import { Stack, useStyleConfig } from "@chakra-ui/react";
import { FC, KeyboardEvent, ReactNode } from "react";

type Props = {
  children: ReactNode;
  onSpacebar: Function;
};

export const JobCardContainer: FC<Props> = ({ children, onSpacebar }) => {
  const handler = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === "Space") {
      onSpacebar();
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const styles = useStyleConfig("JobCardContainer");

  return (
    <Stack
      sx={styles}
      direction={["column", null, "row"]}
      spacing={["0", null, "10"]}
      p={["4", null, "5"]}
      tabIndex={0}
      onKeyDown={handler}
    >
      {children}
    </Stack>
  );
};
