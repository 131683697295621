import { Company, useFeathersService } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { dropdownStyles, useOptionLoader } from "@cx/ui-form";
import { Paginated } from "@feathersjs/feathers";
import { AsyncSelect } from "chakra-react-select";
import { FC } from "react";
import { useJobFilterContext } from "./JobFilterProvider";

export const CompanyDropdown: FC = () => {
  const { setSelectedCompany } = useJobFilterContext();
  const { t } = useTranslation("job.filter");
  const service = useFeathersService("companies");

  const searchCompanies = async ($search: string) => {
    const { data } = (await service.find({
      query: {
        $search,
        $select: ["name"],
      },
    })) as Paginated<Company>;

    return data;
  };
  const optionLoader = useOptionLoader(searchCompanies, 350);

  return (
    <AsyncSelect<Company>
      aria-label="Search company name"
      loadOptions={optionLoader}
      chakraStyles={dropdownStyles}
      getOptionLabel={(company) => company.name}
      getOptionValue={(company) => company._id}
      defaultOptions
      isSearchable
      onChange={(company) => {
        setSelectedCompany(company ? company._id : undefined);
      }}
      inputId="company-name"
      isClearable
      placeholder={t("company")}
    />
  );
};
