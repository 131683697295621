import { Alert, AlertIcon } from "@chakra-ui/react";
import { FC } from "react";
import { useTranslation } from "@cx/react-polyglot";

type Props = {
  valid: string[];
};

export const LocationAlert: FC<Props> = ({ valid }) => {
  const { t } = useTranslation("partners.talentnet");

  return (
    <Alert status="warning" mt="6">
      <AlertIcon />
      {t("invalidLocation", { countries: valid.join(", ") })}
    </Alert>
  );
};
