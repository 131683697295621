import { FC, ReactNode } from "react";
import { useInitCompanyUrls } from "../hooks/use-init-company-urls";

interface Props {
  children: ReactNode;
}

export const ResolveCompanyUrls: FC<Props> = ({ children }) => {
  const ready = useInitCompanyUrls();

  if (!ready) {
    return null;
  }

  return <>{children}</>;
};
