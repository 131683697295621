import { FormRow } from "@cx/ui-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectCandidateDiversty } from "~/src/state/slices/candidate-slice";
import { useUpdateDiversity } from "../Candidate/use-update-candidate";
import {
  DiversityAgeGroup,
  DiversityDisability,
  DiversityEthnicity,
  DiversityGender,
  DiversityMentalHealth,
  DiversitySexualOrientation,
  DiversityStep1Schema,
} from "../Diversity";
import { FormButton } from "./FormButton";

export const DiversityDetailsStep1: FC = () => {
  const candiateDiversity = useSelector(selectCandidateDiversty);

  const methods = useForm<DiversityStep1Schema>({
    resolver: yupResolver(DiversityStep1Schema),
    defaultValues: candiateDiversity,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = useUpdateDiversity("/onboarding/diversity-2");

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <DiversityEthnicity />
          <DiversityAgeGroup />
        </FormRow>

        <FormRow>
          <DiversityGender />
          <DiversitySexualOrientation />
        </FormRow>

        <FormRow>
          <DiversityDisability />
          <DiversityMentalHealth />
        </FormRow>

        <FormButton link="/onboarding/more-detail" loading={isSubmitting} />
      </form>
    </FormProvider>
  );
};
