import { useTranslation } from "@cx/react-polyglot";
import { Dropdown } from "@cx/ui-form";
import { FC } from "react";
import { useDiversityOptions } from "./DiversityOptionsProvider";

export const DiversityAgeGroup: FC = () => {
  const { t } = useTranslation("diversity.step.one");
  const options = useDiversityOptions("age-group");

  return (
    <Dropdown
      aria-label="Age group"
      field="ageGroup"
      isSearchable={false}
      options={options}
      title={t("ageGroup")}
    />
  );
};
