import { Button } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FullPageError } from "@cx/ui-common";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { useHomepageUrl } from "../feature/Company";

export const NotFound: FC = () => {
  const { t } = useTranslation("errors.notFound");
  const hompageUrl = useHomepageUrl();

  return (
    <>
      <Helmet title={t("pageTitle")} />

      <FullPageError
        code="404"
        description={t("description")}
        heading={t("heading")}
      >
        <Button as={NavLink} to={hompageUrl} colorScheme="gray">
          {t("goHome")}
        </Button>
      </FullPageError>
    </>
  );
};
