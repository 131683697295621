import { Company, useFeathersService } from "@cx/feathers-client";
import { Paginated } from "@feathersjs/feathers";
import { useAsync } from "react-use";
import { useCompanyParams } from "./use-company-params";
import { useSetCompanyUrls } from "./use-set-company-urls";

export function useInitCompanyUrls() {
  const params = useCompanyParams();
  const service = useFeathersService("companies");
  const setUrls = useSetCompanyUrls();

  const { loading, error } = useAsync(
    async function () {
      if (!params.slug && !params.partnerId) {
        return;
      }

      if (params.slug) {
        setUrls(params.slug, params.returnUrl);
        return;
      }

      const { data } = (await service.find({
        query: {
          "partner.id": params.partnerId,
          $limit: 1,
        },
      })) as Paginated<Company>;

      const company = data[0];

      if (company) {
        setUrls(company.slug, params.returnUrl);
      }
    },
    [params],
  );

  if (error) {
    // swallow the error
    console.warn(
      "[init-company-urls] Failed to resolve the company by partner id",
      error,
    );
  }

  return !loading;
}
