import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { MarketingForm } from "~/src/feature/Settings/Marketing/MarketingForm";

export const Marketing: FC = () => {
  const { t } = useTranslation("settings.marketing");

  return (
    <>
      <Helmet title={t("pageTitle")} />
      <MarketingForm />
    </>
  );
};
