import {
  Center,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Company, Job, JobApplication } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { useRegionFormattedDate } from "@cx/react-region-config";
import { Modify } from "@cx/types";
import { FC } from "react";
import { PaginationControls } from "~/src/components/PaginationControls";
import { usePagination } from "~/src/hooks/use-pagination";

type PopulatedJobApplications = Modify<
  JobApplication,
  {
    job: Modify<
      Job,
      {
        company: Company;
      }
    >;
  }
>;

export const ApplicationHistoryTable: FC = () => {
  const { t } = useTranslation("settings.application");
  const { error, loading, value, ...pagination } = usePagination(
    "job-applications",
    {
      $limit: 5,
      $populate: {
        path: "job",
        populate: { path: "company" },
      },
    },
  );

  const dateFormat = useRegionFormattedDate();

  if (error || !value) {
    return null;
  }

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (value.data.length == 0) {
    return (
      <Center>
        <Text>{t("emptyState")}</Text>
      </Center>
    );
  }

  const applications = value.data as unknown as PopulatedJobApplications[];

  return (
    <>
      <TableContainer mb="6">
        <Table>
          <Thead>
            <Tr>
              <Th>{t("org")}</Th>
              <Th>{t("role")}</Th>
              <Th>{t("status")}</Th>
              <Th>{t("appliedAt")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {applications.map(
              ({
                _id,
                createdAt,
                status,
                job: {
                  title,
                  company: { name },
                },
              }) => (
                <Tr key={_id}>
                  <Td>
                    <HStack spacing="3">
                      <Text>{name}</Text>
                    </HStack>
                  </Td>
                  <Td>
                    <Text>{title}</Text>
                  </Td>
                  <Td>
                    <Text>{status}</Text>
                  </Td>
                  <Td>
                    <Text>{dateFormat(createdAt)}</Text>
                  </Td>
                </Tr>
              ),
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <PaginationControls pagination={pagination} />
    </>
  );
};
