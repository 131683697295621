import { JobWithCompany } from "@cx/feathers-client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";
import { ObjectSchema } from "yup";
import {
  selectActiveResume,
  selectCandidate,
} from "~/src/state/slices/candidate-slice";
import { PartnerApplications } from "../../PartnerApplications";
import { ApplicationSchema } from "../application-schema";

export function useApplicationForm(job?: JobWithCompany) {
  const resume = useSelector(selectActiveResume);
  const candidate = useSelector(selectCandidate);

  let form: UseFormReturn<ApplicationSchema>;
  let ExtendedSchema: ObjectSchema<any> = ApplicationSchema;
  let currency: string | undefined = candidate.currency;

  if (job?.partner?.ref) {
    const { schemaFactory, currencyResolver } =
      PartnerApplications[job?.partner?.pid];

    if (schemaFactory) {
      ExtendedSchema = ApplicationSchema.concat(schemaFactory(job));
    }

    if (currencyResolver) {
      const currencies = currencyResolver(job);

      if (!currencies.includes(currency)) {
        currency = undefined;
      }
    }
  }

  form = useForm<ApplicationSchema>({
    context: {
      workTypes: job?.workTypes,
      skills: job?.skills,
      isPartner: !!job?.partner?.ref,
    },
    resolver: yupResolver(ExtendedSchema, {
      stripUnknown: true,
    }),
    defaultValues: {
      currency,
      compensation: candidate.compensation,
      job: job?._id,
      resume: resume?._id,
      availableAt: candidate.availableAt?.substring(0, 10),
    },
  });

  return form;
}
