import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import { Logo, ToggleColorMode } from "@cx/ui-common";
import { selectIsAuthenticated } from "@cx/ui-state";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useHomepageUrl } from "~/src/feature/Company";
import { CreateProfileLink } from "./CreateProfileLink";
import { UserMenu } from "./UserMenu";

export const DefaultNavbar: FC = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const hompageUrl = useHomepageUrl();

  const bg = useColorModeValue("lightMode", "darkMode");

  return (
    <Box as="header" mb="6">
      <HStack
        py={[3, 4]}
        px={[3, 6]}
        justify="space-between"
        position="relative"
        zIndex="tooltip"
        borderBottomWidth="1px"
        bg={bg}
        minH={20}
      >
        <Logo link={hompageUrl} aria-label="Redirect to jobs" />

        <HStack>
          <Box display={["none", null, "flex"]}>
            <ToggleColorMode />
          </Box>

          {isAuthenticated ? <UserMenu /> : <CreateProfileLink />}
        </HStack>
      </HStack>
    </Box>
  );
};
