import { selectHasVerifiedEmail, selectIsAuthenticated } from "@cx/ui-state";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GuardFactory } from "./GuardFactory";

const route = "/verification";

function useShouldShowVerification() {
  const isVerified = useSelector(selectHasVerifiedEmail);
  const isAuthed = useSelector(selectIsAuthenticated);
  const { pathname } = useLocation();

  const isTargetPath = pathname === route;

  return isAuthed && !isTargetPath && !isVerified;
}

export const EmailVerifiedGuard = GuardFactory(
  "email-verified",
  route,
  useShouldShowVerification,
  "Email is not verified",
);
