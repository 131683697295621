import { Badge, useStyleConfig, Wrap, WrapItem } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

type SkillsBadgeListProps = {
  skills: string[];
};

type SkillsBadgeProps = {
  children?: ReactNode;
};

export const SkillsBadgeList: FC<SkillsBadgeListProps> = ({ skills }) => {
  return (
    <Wrap>
      {skills.slice(0, 3).map((skill) => (
        <WrapItem key={skill}>
          <SkillsBadge>{skill}</SkillsBadge>
        </WrapItem>
      ))}
    </Wrap>
  );
};

export const SkillsBadge: FC<SkillsBadgeProps> = ({ children }) => {
  const styles = useStyleConfig("JobCardBadge");

  return (
    <Badge sx={styles} variant="ghost">
      {children}
    </Badge>
  );
};
