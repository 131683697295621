import { useFeathersService } from "@cx/feathers-client";
import { useToastStatus } from "@cx/ui-hooks";
import { useAppDispatch } from "@cx/ui-state";
import { useNavigate } from "react-router-dom";
import { setAvailableAt } from "~/src/state/slices/candidate-slice";
import { ApplicationSchema } from "../application-schema";

export function useApplicationSubmit() {
  const toast = useToastStatus();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const service = useFeathersService("job-applications");

  return async (payload: ApplicationSchema) => {
    try {
      const application = await service.create(payload);
      dispatch(setAvailableAt(application.availableAt));

      navigate("success");
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
    }
  };
}
