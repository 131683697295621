import { Box, forwardRef, HStack, Image, ImageProps } from "@chakra-ui/react";
import { Company } from "@cx/feathers-client";
import { ReactNode } from "react";

type Props = ImageProps & {
  company: Company;
  title: string;
  children: ReactNode;
};

export const CompanyAvatar = forwardRef<Props, "img">(
  ({ company, title, children, ...props }, ref) => {
    const fallbackSrc = `https://avatars.dicebear.com/api/initials/${company.name}.svg`;

    return (
      <HStack align="center" mb="4" spacing={4}>
        <Image
          ref={ref}
          src={company.logo}
          fallbackSrc={fallbackSrc}
          h="12"
          w="12"
          borderRadius="8"
          {...props}
        />

        <Box>{children}</Box>
      </HStack>
    );
  },
);
