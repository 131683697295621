import {
  Box,
  Center,
  Image,
  keyframes,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FC } from "react";

type Props = {
  isOpen: boolean;
};

const animationKeyframes = keyframes`
  0% { transform: scale(1) }
  50% { transform: scale(1.1) }
  100% { transform: scale(1) }
`;

const animation = `${animationKeyframes} 10s ease-in-out infinite`;

export const Interstitial: FC<Props> = ({ isOpen }) => {
  const { onClose } = useDisclosure();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay bg="blackAlpha.800" backdropFilter="blur(5px) " />
      <ModalContent>
        <Center px="10">
          <Box as={motion.div} animation={animation}>
            <Image src="/img/loading-logo.svg" alt="Loading logo" maxW="md" />
          </Box>
        </Center>
      </ModalContent>
    </Modal>
  );
};
