import {
  Diversity,
  DiversityTypes,
  useFeathersService,
} from "@cx/feathers-client";
import { useAsync } from "react-use";

export type Option = {
  value: string;
  label: string;
};

function isLastOption(v: string) {
  return v.startsWith("Prefer") || v.startsWith("Not");
}

function sortLabels({ label: a }: Option, { label: b }: Option) {
  if (a === b) {
    return 0;
  }

  if (isLastOption(a)) {
    return 1;
  }

  if (isLastOption(b)) {
    return -1;
  }

  return a > b ? 1 : -1;
}

export function useHydrateDiversityOptions<T extends DiversityTypes>(
  ...groups: T[]
) {
  const service = useFeathersService("diversities");

  const asyncState = useAsync(async () => {
    const diversityOpts = (await service.find({
      query: {
        type: groups,
        $paginate: false,
      },
    })) as Diversity[];

    const groupedDiversityOpts = {} as Record<T, Option[]>;

    for (const group of groups) {
      groupedDiversityOpts[group] = diversityOpts
        .filter((diversity) => diversity.type === group)
        .map((diversity) => ({
          label: diversity.label,
          value: diversity._id,
        }))
        .sort(sortLabels);
    }

    return groupedDiversityOpts;
  }, []);

  return asyncState;
}
