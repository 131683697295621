import {
  Box,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useSelector } from "react-redux";
import { selectCandidate } from "~/src/state/slices/candidate-slice";
import { DropdownMenu } from "./DropdownMenu";
import { MobileDrawer } from "./MobileDrawer";

export const UserMenu: FC = () => {
  const candidate = useSelector(selectCandidate);
  const { t } = useTranslation("navbar");

  const { onToggle, isOpen, onClose } = useDisclosure();
  const bg = useColorModeValue("lightMode", "darkMode");

  return (
    <>
      <Box display={["none", null, "flex"]} alignItems="center" gap="2">
        <Text fontWeight="bold">
          {candidate.firstName} {candidate.lastName}
        </Text>
        <DropdownMenu />
      </Box>

      <Icon
        fontSize="3xl"
        onClick={onToggle}
        display={["block", null, "none"]}
        as={isOpen ? AiOutlineClose : AiOutlineMenu}
        aria-label="Open Menu"
      />

      <MobileDrawer isOpen={isOpen} onClose={onClose} aria-label="Open Menu" />
    </>
  );
};
