import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC } from "react";

type Props = {
  isOpen: boolean;
  exit: () => void;
};

export const InfoModal: FC<Props> = ({ isOpen, exit }) => {
  const bg = useColorModeValue("lightMode", "darkMode");

  return (
    <>
      <Modal isOpen={isOpen} onClose={exit}>
        <ModalOverlay />
        <ModalContent py="10" maxW="container.sm" position="relative">
          <ModalHeader textAlign="center" fontSize="4xl">
            Why apply anonymously?
          </ModalHeader>
          <ModalBody fontSize="xl" px="20">
            <Text py="2">
              Our hiring businesses are committed to creating inclusive hiring
              processes.
            </Text>
            <Text py="2">
              However, when reviewing resumes, human bias exists, whether
              conscious or unconscious, and is a major problem in the creation
              of fair hiring processes.
            </Text>
            <Text py="2">
              We remove identifiable data from your resume which may lead to
              bias or prejudice and ensure an assessment based on your skills
              and experience alone.
            </Text>
          </ModalBody>

          <Box position="absolute" bottom="-0.1rem" right="0">
            <ModalCloseButton
              fontSize={["lg", "3xl"]}
              boxSize={[12, 16]}
              bg={bg}
              rounded="full"
              onClick={exit}
            />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
