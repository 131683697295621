import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FC, ReactNode } from "react";

export const VerifyHeading: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation("candidate.verification", "onboarding");

  return (
    <Box textAlign="center" mb={["85px", "123px"]}>
      <Text variant="cx-step" colorScheme="highlight">
        {t("step", { current: 2 })}
      </Text>
      {children}
    </Box>
  );
};
