import { LocationPreferences } from "@cx/feathers-client";
import { LocationSchema } from "@cx/ui-validation";
import { array, bool, InferType, mixed, object, string } from "yup";

const phoneRegExp = /^\d{10}/;

export const AboutYourselfSchema = object({
  firstName: string().required().label("candidate.aboutYourself.firstName"),

  lastName: string().required().label("candidate.aboutYourself.lastName"),

  phone: string()
    .required()
    .matches(phoneRegExp, "candidate.aboutYourself.phone.invalid")
    .label("candidate.aboutYourself.phone.title"),

  location: LocationSchema.nullable()
    .default(null)
    .required("common.location.invalid"),

  locationPreference: array()
    .of(
      mixed<LocationPreferences>()
        .oneOf([...LocationPreferences])
        .required(),
    )
    .min(1, "common.forms.pleaseSelect")
    .required("common.forms.pleaseSelect")
    .label("candidate.aboutYourself.locationPreference"),

  considerations: bool(),
});

export type AboutYourselfSchema = InferType<typeof AboutYourselfSchema>;
