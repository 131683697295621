import { JobWithCompany } from "@cx/feathers-client";
import { FC, ReactNode } from "react";
import { PartnerApplicationContext } from "./PartnerApplicationContext";
import { usePartnerConfig } from "./use-partner-config";

interface PartnerApplicationProviderProps {
  job: JobWithCompany;
  children: ReactNode;
}

export const PartnerApplicationProvider: FC<
  PartnerApplicationProviderProps
> = ({ job, children }) => {
  const { ApplicationGuard, PartnerForm, currencyResolver } = usePartnerConfig(
    job?.partner?.pid,
  );

  return (
    <PartnerApplicationContext.Provider
      value={{
        currencies: currencyResolver(job),
      }}
    >
      <ApplicationGuard job={job}>
        {/* base application */}
        {children}

        {PartnerForm && <PartnerForm job={job} />}
      </ApplicationGuard>
    </PartnerApplicationContext.Provider>
  );
};
