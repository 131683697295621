import {
  authReducer,
  modalReducer,
  opts,
  rootReducerFactory,
} from "@cx/ui-state";
import { combineReducers } from "redux";
import { app } from "~/lib/feathers";
import { default as candidateReducer } from "./candidate-slice";

const combinedReducers = combineReducers({
  auth: authReducer,
  candidate: candidateReducer,
  modal: modalReducer,
});

type _RootState = ReturnType<typeof combinedReducers>;

export const rootReducer = rootReducerFactory(
  combinedReducers,
  (state) =>
    ({
      modal: state?.modal,
    }) as _RootState,
);

opts.set("app", app);

declare module "@cx/ui-state" {
  export interface RootState extends _RootState {}
}
