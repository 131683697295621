import { useContext } from "react";
import { PartnerApplicationContext } from "./PartnerApplicationContext";

export const usePartnerApplication = () => {
  const context = useContext(PartnerApplicationContext);

  if (!context) {
    throw new Error(
      "PartnerApplicationContext is undefined, please nest it in an ResumeViewerProvider",
    );
  }

  return context;
};

export const usePartnerCurrencies = () => {
  return usePartnerApplication().currencies;
};
