import { Button } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { selectIsAuthenticated } from "@cx/ui-state";
import { FC } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useAuthRedirect } from "../../Auth/hooks/use-auth-redirect";

type Props = {
  applied: boolean;
  id: string;
};

export const ApplyButton: FC<Props> = ({ applied, id }) => {
  const { t } = useTranslation("job.card");

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { setApplicationUrl } = useAuthRedirect();

  if (applied) {
    return (
      <Button size="md" isDisabled>
        {t("applied")}
      </Button>
    );
  }

  if (isAuthenticated) {
    return (
      <Button as={NavLink} to={`/application/${id}`} size="md">
        {t("apply")}
      </Button>
    );
  }

  return (
    <Button
      as={NavLink}
      to="/register"
      size="md"
      onClick={() => {
        setApplicationUrl(id);
      }}
    >
      {t("register")}
    </Button>
  );
};
