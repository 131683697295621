import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export function useCompanyParams() {
  const [params] = useSearchParams();

  return useMemo(
    () => ({
      slug: params.get("company"),
      partnerId: params.get("partnerId"),
      returnUrl: params.get("returnUrl") ?? document.referrer,
    }),
    [params],
  );
}
