import { useStorage } from "~/src/hooks/use-storage";
import { CompanyUrls } from "./company-urls.types";

export function useCompanyUrls() {
  return useStorage<CompanyUrls>(sessionStorage, "company:urls");
}

export function useCompanyHomeUrl() {
  return useCompanyUrls().value?.home;
}

export function useCompanyPartnerUrl() {
  return useCompanyUrls().value?.partner ?? "/";
}

export function useHomepageUrl() {
  return useCompanyHomeUrl() ?? "/";
}
