import { useTranslation } from "@cx/react-polyglot";
import { Dropdown } from "@cx/ui-form";
import { FC } from "react";
import { useDiversityOptions } from "./DiversityOptionsProvider";

export const DiversityVeteran: FC = () => {
  const { t } = useTranslation("diversity.step.two");
  const options = useDiversityOptions("veteran");

  return (
    <Dropdown
      aria-label="Veteran"
      field="veteran"
      isSearchable={false}
      options={options}
      title={t("veteran")}
    />
  );
};
