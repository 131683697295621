import { Center, Heading } from "@chakra-ui/react";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "@cx/react-polyglot";

export const PrivacyPolicy: FC = () => {
  const { t } = useTranslation("legal.privacy");

  return (
    <Center>
      <Helmet title={t("pageTitle")} />
      <Heading>{t("pageTitle")}</Heading>
    </Center>
  );
};
