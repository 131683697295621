import { Box, Container, Image } from "@chakra-ui/react";
import { Company } from "@cx/feathers-client";
import { EditorContent } from "@cx/ui-common";
import { usePlaceholderImage } from "@cx/ui-hooks";
import { FC } from "react";
import { JobListContainer } from "../../JobBoard/JobListContainer";
import { CompanyHeader } from "./CompanyHeader";

type Props = {
  company: Company;
};

export const CompanyJobList: FC<Props> = ({ company }) => {
  const fallbackSrc = usePlaceholderImage({
    height: 300,
    width: 600,
    bgColor: "black",
    textColor: "white",
    text: company?.name,
  });

  const src = company.banner ?? fallbackSrc;

  return (
    <>
      <Box
        h={["48", "72", "96"]}
        mt="-6"
        overflow="hidden"
        pos="relative"
        w="100%"
      >
        <Image
          _groupHover={{ transform: "scale(1.05)" }}
          h="full"
          loading="lazy"
          objectFit="cover"
          objectPosition="center"
          src={src}
          transition="all 0.2s"
          width="full"
        />
      </Box>

      <Container px="0">
        <CompanyHeader company={company} />

        <EditorContent maxW="container.md" mt="6" mx="auto">
          {company.description}
        </EditorContent>

        <Box py="10">
          <JobListContainer focusedCompany={company._id} />
        </Box>
      </Container>
    </>
  );
};
