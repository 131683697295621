import { taggedMessage } from "@cx/format";
import { useLoginRedirect } from "./use-login-redirect";
import { useRegisterRedirect } from "./use-registration-redirect";

const msg = taggedMessage("use-auth-redirect");

export function useAuthRedirect() {
  const { setRegisterRedirect } = useRegisterRedirect();
  const { setLoginRedirect } = useLoginRedirect();

  function setApplicationUrl(id: string) {
    const url = `/application/${id}`;

    console.debug(msg`Setting redirect url to ${url}`);

    setRegisterRedirect(url);
    setLoginRedirect(url);
  }

  function clear() {
    console.debug(msg`Clearing redirect url`);

    setRegisterRedirect(undefined);
    setLoginRedirect(undefined);
  }

  return {
    setApplicationUrl,
    clear,
  };
}
