import { Box, Stack } from "@chakra-ui/react";
import { FC } from "react";
import { CompanyDropdown } from "./CompanyDropdown";
import { useJobFilterContext } from "./JobFilterProvider";
import { JobSearch } from "./JobSearch";
import { LocationSearch } from "./LocationSearch";

export const JobFilters: FC = () => {
  const { focused } = useJobFilterContext();

  return (
    <>
      <Box mb="6">
        <JobSearch />
      </Box>
      <Stack spacing="6" direction={["column", "row"]} justify="center">
        {!focused && <CompanyDropdown />}
        <LocationSearch />
      </Stack>
    </>
  );
};
