import { JobWithCompany, useFeathersService } from "@cx/feathers-client";
import { useAsync } from "react-use";

export function useJobWithCompany(id?: string) {
  const service = useFeathersService("jobs");

  return useAsync(async () => {
    if (!id) {
      return;
    }
    const job = await service.get(id, {
      query: {
        $populate: "company",
      },
    });

    return job as unknown as JobWithCompany;
  }, []);
}
