import { Image, Stack } from "@chakra-ui/react";
import { FC } from "react";

const images = [1, 2, 3, 4].map((num) => `/img/footer/${num}.png`);

export const PartnerBadges: FC = () => {
  return (
    <Stack
      align="center"
      direction={["column", "row"]}
      flexWrap="wrap"
      justify="center"
      spacing={[6, 10]}
      mb="10"
    >
      {images.map((src) => (
        <Image
          key={`footer-${src}`}
          boxSize="20"
          objectFit="cover"
          p={[1, null, 0]}
          src={src}
          w="auto"
        />
      ))}
    </Stack>
  );
};
