import { ErrorBoundedOutlet } from "@cx/ui-features";
import { FC } from "react";
import { AuthGuard } from "./Guards";

export const ProtectedLayout: FC = () => {
  return (
    <AuthGuard>
      <ErrorBoundedOutlet />
    </AuthGuard>
  );
};
