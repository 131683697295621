import { Text } from "@chakra-ui/react";
import { WorkTypes } from "@cx/feathers-client";
import { currencyFormatterFactory } from "@cx/format";
import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";

type Props = {
  workType: WorkTypes;
  rateRange?: [number, number?];
  currency?: string;
};

export const WorkTypeRate: FC<Props> = ({ workType, rateRange, currency }) => {
  const { t } = useTranslation("job.card", "candidate.moreDetails");

  if (!rateRange?.length) {
    return <Text fontSize="sm">{t(`${workType}.title`)}</Text>;
  }

  const [min, max] = rateRange;

  const format = currencyFormatterFactory({
    currency,
    notation: "compact",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const rateText = max
    ? t(`rates.range.${workType}`, {
        min: format(min),
        max: format(max),
      })
    : t(`rates.single.${workType}`, {
        value: format(min),
      });

  return <Text fontSize="sm">{rateText}</Text>;
};
