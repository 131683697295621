import { Route } from "react-router-dom";
import { OnboardingLayout } from "~/src/components/Layouts";
import { DiversityData } from ".";

export const DiversityDataRoute = (
  <>
    <Route path="/onboarding" element={<OnboardingLayout clear />}>
      <Route path="diversity-data" element={<DiversityData />} />
    </Route>
  </>
);
