import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { HeaderInfo } from "~/src/feature/HeaderInfo";
import { CandidateDetail } from "~/src/feature/Onboarding/CandidateMoreDetails";

export const MoreDetails: FC = () => {
  const { t } = useTranslation("candidate.moreDetails", "onboarding");

  return (
    <>
      <HeaderInfo
        heading={t("addMore")}
        pageTitle={t("pageTitle")}
        step={t("step", { current: 4 })}
      />
      <CandidateDetail />
    </>
  );
};
