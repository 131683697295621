import { Box, Checkbox, Heading, useStyleConfig } from "@chakra-ui/react";
import { Company } from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { Dropdown, FormCheckbox, FormRow } from "@cx/ui-form";
import { useSelector } from "react-redux";
import { selectCandidate } from "~/src/state/slices/candidate-slice";
import { metadataPrefix } from "../../metadata-prefix";
import { PartnerForm } from "../../partner.type";
import { TenantConfiguration } from "../tenant-config.type";

export const TalentNetForm: PartnerForm = ({ job }) => {
  const company: Company<TenantConfiguration> = job.company;
  const candidate = useSelector(selectCandidate);
  const { t } = useTranslation("partners.talentnet");
  const linkStyles = useStyleConfig("Link");

  if (!company.partner?.metadata || !candidate.location?.country) {
    return null;
  }

  const { consents, taxStatuses } = company.partner.metadata;
  const { country } = candidate.location;
  const taxStatusOptions = taxStatuses[country];

  return (
    <>
      {/* tax status */}
      <FormRow>
        <Dropdown
          field={metadataPrefix("taxStatus")}
          options={taxStatusOptions}
          title={t("taxStatus")}
          aria-label={t("taxStatus")}
        />
      </FormRow>

      {/* consents */}
      <Heading as="h3" size="sm" mt="6">
        {t("consents.title")}
      </Heading>
      <FormRow
        direction="column"
        spacing="4"
        sx={{
          a: {
            ...linkStyles,
          },
        }}
      >
        {consents.map(({ name, description, required }) => (
          <FormCheckbox
            field={metadataPrefix(`consents.${name}`)}
            isRequired={required}
            key={name}
          >
            <Box dangerouslySetInnerHTML={{ __html: description }}></Box>
          </FormCheckbox>
        ))}
      </FormRow>
    </>
  );
};
