import { Heading } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { ResendVerificationLink } from "@cx/ui-features";
import { selectUser } from "@cx/ui-state";
import { FC } from "react";
import { useSelector } from "react-redux";
import { AuthGuard } from "~/src/components/Layouts/Guards";
import { VerifyHeading } from "~/src/feature/Onboarding/VerifyHeading";

export const VerifyEmailPrompt: FC = () => {
  const user = useSelector(selectUser);
  const { t } = useTranslation("auth.verification");

  return (
    <AuthGuard>
      <VerifyHeading>
        <Heading variant="cx-title">{t("heading")}</Heading>
      </VerifyHeading>

      <ResendVerificationLink email={user.email} />
    </AuthGuard>
  );
};
