import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "@cx/react-polyglot";
import { ProfileForm } from "~/src/feature/Settings/Profile";

export const Profile: FC = () => {
  const { t } = useTranslation("settings.profile");

  return (
    <>
      <Helmet title={t("pageTitle")} />
      <ProfileForm />
    </>
  );
};
