import { useTranslation } from "@cx/react-polyglot";
import { Dropdown } from "@cx/ui-form";
import { FC } from "react";
import { useDiversityOptions } from "./DiversityOptionsProvider";

export const DiversitySexualOrientation: FC = () => {
  const { t } = useTranslation("diversity.step.one");
  const options = useDiversityOptions("sexual-orientation");

  return (
    <Dropdown
      aria-label="Sexual orientation"
      field="sexualOrientation"
      isSearchable={false}
      options={options}
      title={t("sexualOrientation")}
    />
  );
};
