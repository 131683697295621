import { useStorage } from "~/src/hooks/use-storage";
import { RedirectUrl } from "./redirect.types";

export function useRegisterRedirect() {
  // useLocalStorage is broken so we have to rig up a custom one
  const { value, set } = useStorage<RedirectUrl>(
    localStorage,
    "after:register",
  );

  return {
    registerRedirect: value,
    setRegisterRedirect: set,
  };
}
