import { Candidate } from "@cx/feathers-client";
import { taggedMessage } from "@cx/format";
import { useAppDispatch } from "@cx/ui-state";
import { useNavigate } from "react-router-dom";
import {
  updateCandidate,
  updateCandidateDiversity,
} from "~/src/state/slices/candidate-slice";

const msg = taggedMessage("use-candidate-update");

export const useUpdateCandidate = (successRoute: string) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return async function onSubmit(data: Partial<Candidate>) {
    console.debug(msg`Updating candidate...`);

    try {
      await dispatch(updateCandidate(data)).unwrap();
      navigate(successRoute);
    } catch (error) {
      console.error(error);
    }
  };
};

export const useUpdateDiversity = (successRoute: string) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return async function onSubmit(data: Partial<Candidate["diversity"]>) {
    console.debug(msg`Updating candidate diversity...`);

    try {
      await dispatch(updateCandidateDiversity(data)).unwrap();
      navigate(successRoute);
    } catch (error) {
      console.error(error);
    }
  };
};
