import { Route } from "react-router-dom";
import { DefaultLayout, ProtectedLayout } from "~/src/components/Layouts";
import { Success } from "~/src/components/Success";
import { CompanyGuard } from "~/src/feature/Company";
import { Application } from "./Application";
import { CompanyProfile } from "./CompanyProfile";
import { JobBoard } from "./JobBoard";
import { JobDetail } from "./JobDetail";

export const JobRoute = (
  <>
    <Route path="/" element={<DefaultLayout />}>
      <Route
        index
        element={
          <CompanyGuard>
            <JobBoard />
          </CompanyGuard>
        }
      />
      <Route path="jobs/:id" element={<JobDetail />} />
      <Route path="company/:id" element={<CompanyProfile />} />

      <Route path="application" element={<ProtectedLayout />}>
        <Route path=":id" element={<Application />} />
        <Route path=":id/success" element={<Success />} />
      </Route>
    </Route>
  </>
);
