import { Box, Center, Container, Heading, Spinner } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { waitAtLeast } from "@cx/sleep";
import { useAppDispatch } from "@cx/ui-state";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { useRegisterRedirect } from "~/src/feature/Auth/hooks/use-registration-redirect";
import { useHomepageUrl } from "~/src/feature/Company";
import { updateCandidate } from "~/src/state/slices/candidate-slice";

export const ThankYou: FC = () => {
  const { t } = useTranslation("diversity.thanks");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { registerRedirect } = useRegisterRedirect();
  const hompageUrl = useHomepageUrl();

  useEffectOnce(() => {
    async function redirectPage() {
      await waitAtLeast(
        () => dispatch(updateCandidate({ onboarding: false })).unwrap(),
        3500,
      );

      navigate(registerRedirect ?? hompageUrl);
    }

    redirectPage();
  });

  return (
    <Container>
      <Helmet title={t("pageTitle")} />
      <Box mt={{ base: "28", lg: "52" }} maxW="container.md" mx="auto">
        <Heading
          fontSize={{ base: "xx-large", lg: "xxx-large" }}
          textAlign="center"
        >
          {t("outro")}
        </Heading>
        <Center>
          <Spinner my="10" />
        </Center>
      </Box>
    </Container>
  );
};
