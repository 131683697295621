import { DiversityTypes } from "@cx/feathers-client";
import { createContext, FC, useContext } from "react";
import {
  useHydrateDiversityOptions,
  Option,
} from "./use-hydrate-diversity-options";

type DiversityOptions = Record<DiversityTypes, Option[]>;
const DiversityOptionsContext = createContext({} as DiversityOptions);

type Props = {
  sections?: DiversityTypes[];
  children: React.ReactNode;
};

export const DiversityOptionsProvider: FC<Props> = ({
  children,
  sections = DiversityTypes,
}) => {
  const { loading, error, value } = useHydrateDiversityOptions(...sections);

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  if (!value) {
    return null;
  }

  return (
    <DiversityOptionsContext.Provider value={value}>
      {children}
    </DiversityOptionsContext.Provider>
  );
};

export const useDiversityOptions = <K extends DiversityTypes>(key: K) => {
  const options = useContext(DiversityOptionsContext);

  return options[key];
};
