import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  IconButtonProps,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { ToggleColorMode } from "@cx/ui-common";
import { useSignOut } from "@cx/ui-hooks";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useMenuItems } from "./use-menu-items";

type Props = IconButtonProps & {
  isOpen: boolean;
  onClose: () => void;
};

export const MobileDrawer: FC<Props> = ({ isOpen, onClose }) => {
  const menuList = useMenuItems();
  const signOut = useSignOut();
  const { t } = useTranslation("common");

  const colorText = useColorModeValue(
    t("colorMode.dark"),
    t("colorMode.light"),
  );

  return (
    <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
      <DrawerContent display={["block", null, "none"]} overflow="auto">
        <DrawerBody mt="24">
          <Stack spacing="6" align="start">
            {menuList.map(({ label, href, altLabel }) => (
              <Button
                as={NavLink}
                key={label}
                to={`/settings/${href}`}
                variant="unstyled"
                py="2"
                onClick={onClose}
              >
                {altLabel ?? label}
              </Button>
            ))}
            <Divider />
          </Stack>

          <Stack align="stretch" spacing="6" w="full" my="10">
            <ToggleColorMode variant="outline" colorScheme="gray">
              {colorText}
            </ToggleColorMode>
            <Button onClick={signOut} w="full">
              {t("signOut")}
            </Button>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
