import { Candidate, PopulatedCandidate, Resume } from "@cx/feathers-client";
import { opts, RootState } from "@cx/ui-state";
import { FeathersError } from "@feathersjs/errors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { selectCandidateDiversty } from "./candidate.selectors";

const query = {
  $populate: "industry",
};
const idAlias = "me" as const;

// fetch candidate
export const fetchCandidate = createAsyncThunk<
  PopulatedCandidate,
  void,
  { state: RootState }
>("candidate/fetch", async (_, { rejectWithValue }) => {
  try {
    const candidate = (await opts
      .get("app")
      .service("candidates")
      .get(idAlias, {
        query,
      })) as PopulatedCandidate;

    return candidate;
  } catch (error) {
    const { className, code, data, message, name } = error as FeathersError;
    return rejectWithValue({ className, code, data, message, name });
  }
});

// update candidate
export const updateCandidate = createAsyncThunk<
  PopulatedCandidate,
  Partial<Candidate>,
  { state: RootState }
>("candidate/update", async (data) => {
  return (await opts.get("app").service("candidates").patch(idAlias, data, {
    query,
  })) as PopulatedCandidate;
});

// add resume
export const addResume = createAsyncThunk<
  Resume,
  FormData,
  { state: RootState }
>("resume/add", async (data) => {
  return await opts.get("app").service("candidates").addResume(idAlias, data);
});

// redact resume
export const redactResume = createAsyncThunk<
  Resume,
  string,
  { state: RootState }
>("resume/redact", async (resumeId) => {
  return await opts
    .get("app")
    .service("candidates")
    .redactResume(idAlias, resumeId);
});

// update candidate diversity
export const updateCandidateDiversity = createAsyncThunk<
  PopulatedCandidate,
  Partial<Candidate["diversity"]>,
  { state: RootState }
>("candidate/updateDiversity", async (data, { getState }) => {
  const existing = selectCandidateDiversty(getState());

  const diversity: Candidate["diversity"] = {
    ...existing,
    ...data,
  };

  return (await opts
    .get("app")
    .service("candidates")
    .patch(idAlias, { diversity }, { query })) as PopulatedCandidate;
});
