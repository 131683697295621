import {
  Divider,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { useSignOut } from "@cx/ui-hooks";
import { FC } from "react";
import { CgLogOut } from "react-icons/cg";
import { NavLink } from "react-router-dom";
import { useMenuItems } from "./use-menu-items";

export const DropdownMenu: FC = () => {
  const signOut = useSignOut();
  const { t } = useTranslation("common");
  const menuList = useMenuItems();

  return (
    <Menu>
      <MenuButton aria-label="Options">
        <Image
          boxSize={9}
          w="full"
          borderRadius="full"
          src="/img/logo-small.svg"
          alt="CanadidateX Icon"
        />
      </MenuButton>
      <MenuList zIndex="dropdown">
        {menuList.map(({ iconName, label, href, altLabel }) => (
          <MenuItem as={NavLink} to={`/settings/${href}`} key={label} py="2.5">
            <Icon as={iconName} fontSize="xl" />
            <Text ml="4">{altLabel ?? label}</Text>
          </MenuItem>
        ))}
        <Divider borderColor="whiteAlpha.400" my="2" />
        <MenuItem onClick={signOut}>
          <Icon as={CgLogOut} fontSize="2xl" />
          <Text ml="4">{t("signOut")}</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
