import { RegisterSchema } from "@cx/ui-features";

export const MarketingPreferencesSchema = RegisterSchema.pick([
  "allowPromotions",
]);

export type MarketingPreferencesSchema = Pick<
  RegisterSchema,
  "allowPromotions"
>;
