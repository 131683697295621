import {
  Box,
  Container,
  Divider,
  HStack,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { ExternalLink } from "@cx/ui-common";
import { FC } from "react";
import {
  TiSocialFacebookCircular,
  TiSocialInstagramCircular,
  TiSocialLinkedinCircular,
  TiSocialTwitterCircular,
} from "react-icons/ti";
import { NavLink } from "react-router-dom";
import { useHomepageUrl } from "~/src/feature/Company";
import { FooterLink } from "./FooterLink";
import { PartnerBadges } from "./PartnerBadges";

export const JobBoardFooter: FC = () => {
  const { t } = useTranslation("footer");
  const hompageUrl = useHomepageUrl();

  return (
    <Box
      bg="black"
      as="footer"
      pos="relative"
      maxW="full"
      sx={{
        a: {
          color: "dark.text !important",
        },
      }}
    >
      <Container>
        <Stack
          py="12"
          justify="space-between"
          align="start"
          direction={["column", null, "row"]}
          spacing={[9, 12]}
        >
          <Box as={NavLink} to={hompageUrl}>
            <Image
              src="/img/cx-logo-light.svg"
              alt="CandidateX Logotype"
              w="44"
            />
          </Box>

          <SimpleGrid columns={[1, 2, 4]} spacing={[10, 6]}>
            <FooterLink title="contact">
              <ExternalLink href="https://www.candidatex.co/faqs/">
                FAQ/Support
              </ExternalLink>

              {/* Social Icon */}
              <HStack>
                <ExternalLink href="https://www.facebook.com/candidatex/">
                  <Icon as={TiSocialFacebookCircular} boxSize="8" />
                </ExternalLink>
                <ExternalLink href="https://www.instagram.com/we_are_candidatex/">
                  <Icon as={TiSocialInstagramCircular} boxSize="8" />
                </ExternalLink>
                <ExternalLink href="https://twitter.com/wearecandidatex">
                  <Icon as={TiSocialTwitterCircular} boxSize="8" />
                </ExternalLink>
                <ExternalLink href="https://www.linkedin.com/company/candidatex/">
                  <Icon as={TiSocialLinkedinCircular} boxSize="8" />
                </ExternalLink>
              </HStack>
            </FooterLink>

            <FooterLink title="About Us">
              <ExternalLink href="https://www.candidatex.co/the-cx-team/">
                {t("mission")}
              </ExternalLink>
              <ExternalLink href="https://www.candidatex.co/better-human/">
                {t("betterHuman")}
              </ExternalLink>
            </FooterLink>

            <FooterLink title="Legals">
              <ExternalLink href="https://www.candidatex.co/privacy-policy/">
                {t("privacyPolicy")}
              </ExternalLink>
              <ExternalLink href="https://www.candidatex.co/terms/">
                {t("terms")}
              </ExternalLink>
              <ExternalLink href="https://www.candidatex.co/accessibility-policy/">
                {t("accessibility")}
              </ExternalLink>
            </FooterLink>
          </SimpleGrid>
        </Stack>

        <PartnerBadges />
        <Divider />

        <Text fontSize="sm" py="8" color="gray.400">
          &copy; {new Date().getFullYear()} {t("copyright")}
        </Text>
      </Container>
    </Box>
  );
};
