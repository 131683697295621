import { useSelector } from "react-redux";
import { selectOnboarding } from "~/src/state/slices/candidate-slice";
import { GuardFactory } from "./GuardFactory";

export const OnboardingGuard = GuardFactory(
  "onboarding",
  "/onboarding/about-yourself",
  () => useSelector(selectOnboarding),
  "Onboarding is incomplete",
);
